import { Inject, Injectable } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { reportDefinitions } from '../../../../coast/constant-definitions/report-definitions';
import { ReportType } from '../../constants/constants';
import { PatientStateService, ChatStateService } from '../state';
import moment from 'moment';

import { AssessmentTemplate, ReportDefinition, ReportViewer, SimpleInstance, SleepPrescription } from './models';
import { CalculationService } from './calculation.service';
import { SleepPrescriptionService, AssessmentDefinitionService, TimedSessionReadingService, AssessmentService, UserFuncService } from './services';

import { additionalInfo, clinicalInterpretation, clinicalSummaryText, defaultFieldText, medicationsDetailsText, medicationUseText, notesText, patientChallengesText, sleepGoalsText, tacticText } from '../../../../coast/constant-definitions/report-definitions/intervention-report';
import { AssessmentFieldDefinition, AssessmentValueDefinition, BaseCalculationDefinition, CalculationsValueContainer, FieldContainer, FieldValueDefinitionBase, Result, UnitType } from '../../core/contracts/models.calculations';
import { CALC_CONFIG } from '@noctem/web';

@Injectable({
  providedIn: 'root'
})
export class ReportService {

  constructor(
    private patientStateService: PatientStateService,
    private domSanitizer: DomSanitizer,
    private calculationService: CalculationService,
    private prescriptionService: SleepPrescriptionService,
    private assessmentDefinitionService: AssessmentDefinitionService,
    private assessmentService: AssessmentService,
    private timedSessionReadingService: TimedSessionReadingService,
    private chatStateService: ChatStateService,
    private userFuncService: UserFuncService,
    @Inject(CALC_CONFIG) private calcConfig: BaseCalculationDefinition
  ) {}
  private readonly DATE_FORMAT = 'MMMM D, YYYY';
  private reportDef: ReportDefinition;
  private weeksMap = [];
  private patientId: string;
  private patientGroups: any[];
  private traumaBaseline = false;
  private osaBaseline = '';
  private isLegacy = false;
  private timeSessionResults = '';
  private messagingResults = '';

  public async getReportById(reportId: ReportType): Promise<ReportViewer> {
    this.getReportDefinitionById(reportId);
    return await this.getReportData();
  }

  private getReportDefinitionById(reportId: ReportType ) {
    this.reportDef = reportDefinitions.find(def => def.id === reportId);
  }

  private async getReportData(): Promise<ReportViewer> {
    const dataValues = new Map<string, Map<string, string>>();
    const patientState = this.patientStateService.stateModel.get();
    const patient = patientState.patientInfo.profile;
    this.patientId = patientState.patientInfo.UserId;

    // For Intermountain, rationalize "not administered" vs "Not answered" for certain assessments
    this.patientGroups = patientState.patientInfo.groups;
    
    // Do this here (smh) ...
    this.chatStateService.setRecipient(this.patientId);

    const TSD = new Date(patientState.patientInfo.treatmentStartDate);

    // Given the database stores treatmentStartDate in UTC, adjust to the caller's offset (at TSD)
    TSD.setMinutes(TSD.getMinutes() + new Date(TSD).getTimezoneOffset());

    const treatmentStartDate = moment(TSD).format(this.DATE_FORMAT);
    // Set isLegavy
    this.isLegacy = await this.isLegacyPhq(patientState.patientInfo.groups);
    const currentDate = moment().format(this.DATE_FORMAT);
    const overallData = new Map<string, string>();
    overallData.set('**treatmentStartDate**', treatmentStartDate);
    overallData.set('**currentDate**', currentDate);
    overallData.set('**patientId**', `${patient.lastName} ${patient.firstName}`);
    overallData.set('**externalId**', `${patient.externalId || "N/A"}`);

    let diagnosticCodes = patient.diagnosticCodes;
    let diagnosticCodesString = "N/A";
    try {
      diagnosticCodesString = (diagnosticCodes.length > 0) ? diagnosticCodes.join("; ") : "N/A";
    } catch (e) {
      // Value is "N/A"
      ;
    }

    overallData.set('**diagnosticCodes**', `${diagnosticCodesString}`);

    // Aggregations go here
    let RTMSummary: any[] = [];

    // Code 98975 (Patient setup)
    RTMSummary.push({
        "c": "98975",
        "jot": "Patient setup & education",
        "sd": new Date(TSD),
        "ed": new Date(TSD),
        "v": `N/A`,
    });    

    // Code 98978 (CBT)
    const morningLogGroups = await this.assessmentService.getAllAsync({ "Payload.user.id": this.patientId, "Payload.groups.type": "morningLog", "Payload.isIncomplete": false});
    const morningLogDates = morningLogGroups.map((o) => {
      const MLD = new Date(o.assessmentDate);
      // Given the database stores assessmentDate in UTC, adjust to the caller's offset (at MLD)
      MLD.setMinutes(MLD.getMinutes() + new Date(MLD).getTimezoneOffset());
      return MLD;
    });

    // Ensure each day is counted only once (testing showed duplicate assessmentDates for morningLogs in some cases.) Strip HMS then convert to an integer.
    let morningLogTimes = morningLogDates.map((o) => {
      o.setUTCHours(0);
      o.setUTCMinutes(new Date(o).getTimezoneOffset());
      o.setUTCSeconds(0);
      o.setUTCMilliseconds(0);
      return o.getTime();
    });

    // Remove duplicates
    morningLogTimes = [... new Set(morningLogTimes)];

    // TODO: refactor to show rows when no logs have been completed?
    let a = new Date(TSD);
    let b = new Date(TSD);
    while(true) {
      const days = 30;
      a = new Date(b);
      b.setDate(b.getDate() + days);
      const c = morningLogTimes.filter((d) => {
        return (d >= a.getTime() && d < b.getTime());
      })

      if (!c.length) { break; }
      
      RTMSummary.push({
        "c": "98978",
        "jot": "CBT",
        "sd": new Date(a),
        "ed": new Date(b),
        "v": `<strong>${c.length}</strong> days collected / <strong>${days}</strong> days`,
      })
    };

    // Codes 98980, 98981 (RTM in 20m units)
    a = new Date(TSD);
    b = new Date(TSD);

    // The end of the caller's current month
    const z = new Date();
    z.setUTCDate(1);
    z.setUTCMonth(z.getUTCMonth() + 1);
    z.setUTCDate(0);
    z.setUTCHours(a.getUTCHours());
    z.setUTCMinutes(a.getUTCMinutes());
    z.setUTCSeconds(a.getUTCSeconds());
    z.setUTCMilliseconds(a.getUTCMilliseconds());

    while(true) {
      a = new Date(b);
      b = new Date(a);
      b.setUTCDate(1);
      b.setUTCMonth(b.getUTCMonth() + 1);

      if (a > z) { break; }
      
      const response = this.timedSessionReadingService.getTimedSessions({
        patientId: this.patientId,
        startTimeMs: a.getTime(),
        endTimeMs: b.getTime(),
        billingPeriodDays: Math.floor((b.getTime() - a.getTime()) / (1000 * 60 * 60 * 24)),
      }).toPromise();
      let i = 0;

      (await response).forEach(o => {
          // The first row of the response is a summary object (to discard in this context)
          if (i != 0) {
            // TODO: delete
            // console.debug("TSS_Debug:", o);

            let _ts = 0;
            o.timeWithPatient.forEach(p => {
              _ts += p.totalSeconds;
            })
            
            // 1 RTM = 20 minutes = 1200 seconds
            if (_ts > 0) {
              RTMSummary.push({
                "c": "98980",
                "jot": "Remote therapeutic monitoring treatment management services: first 20 minutes",
                "sd": new Date(a),
                "ed": new Date(b),
                "v": `Unit(s) completed: <strong>${(Math.floor(_ts/1200)) ? 1 : 0}</strong><br>Total time: ${(_ts < 1200) ? this.calcMS(_ts) : this.calcMS(1200)}`,
              });
            }

            // Subtract 1200 seconds for code 98980
            _ts -= 1200;
            // if (_ts < 0) { _ts = 0; }

            if (_ts > 0) {
              RTMSummary.push({
                "c": "98981",
                "jot": "Remote therapeutic monitoring treatment management services: each additional 20-minute unit",
                "sd": new Date(a),
                "ed": new Date(b),
                "v": `Unit(s) completed: <strong>${Math.floor(_ts/1200)}</strong><br>Total time: ${this.calcMS(_ts)}`,
              });
            }
          }
          i++;
      });

    };

    // Sort first by code, then startDate
    RTMSummary.sort((a, b) => {
      return (a.c > b.c) ? 1 : (a.c < b.c) ? -1 : 0;
    });
    
    RTMSummary.sort((a, b) => {
      return (a.sd > b.sd) ? 1 : (a.sd < b.sd) ? -1 : 0;
    });

    // Rewrite endDate to 1ms prior so that both startDate and endDate display as inclusive. Don't however where startDate = endDate.
    RTMSummary.map(o => {
      if (o.sd.getTime() != o.ed.getTime()) {
        o.ed.setUTCMilliseconds(o.ed.getUTCMilliseconds() - 1);
      }
    })
    
    // TODO: delete
    // console.debug("RTMSummary", RTMSummary);

    // Show the caller's timezone for context
    const _df = new Intl.DateTimeFormat();
    this.timeSessionResults += `<label class='col-sm-12'>Billing calculations reported in ${_df.resolvedOptions().timeZone} time:</label>`;

    this.timeSessionResults += `<label class='col-sm-12' style='font-size:1px;'>&nbsp;</label>`; // Small spacer between the description & report entries
    this.timeSessionResults += `
      <label class="col-sm-12">
      <table class="table table-bordered">
      <tr>
      <th class="text-bold">Code</th>
      <th class="text-bold">Description</th>
      <th class="text-bold">Start&nbsp;Date</th>
      <th class="text-bold">End&nbsp;Date</th>
      <th class="text-bold">Supporting&nbsp;Information</th>
      </tr>
    `;

    RTMSummary.forEach(o => {
      this.timeSessionResults += `
        <tr>
        <td class="align-top text-bold">${o.c}</td>
        <td class="align-top">${o.jot}</td>
        <td class="align-top">${moment(o.sd).format("MM/DD/YYYY")}</td>
        <td class="align-top">${moment(o.ed).format("MM/DD/YYYY")}</td>
        <td class="align-top">${o.v}</td>
        </tr>
      `;
    });

    this.timeSessionResults += `
      </table>
      </label>
    `;
    
    overallData.set(`**timeSessionResults**`, this.timeSessionResults);

    // Messages
    const chatState = this.chatStateService.stateModel.get();

    // Summarize messages
    const mObj = {
      patient: {
        messageCount: 0,
        senders: new Set(),
      },
      provider: {
        messageCount: 0,
        senders: new Set(),
      }
    };
    
    chatState.messages.forEach((m) => {
      const senderPersona = m.fromRecipient ? "patient" : "provider";
      mObj[senderPersona]["messageCount"] += 1;
      mObj[senderPersona]["senders"].add(m.senderDisplay);
    });

    const totalClinicians = (mObj.provider.senders.has("System Autoreply") || mObj.provider.senders.has("System AutoReply")) ? mObj.provider.senders.size - 1 : mObj.provider.senders.size;
    const totalMessages = mObj.patient.messageCount + mObj.provider.messageCount;


    // Print transcript
    if (totalMessages > 0) {
      this.messagingResults += `
        <section>
        <label class="col-sm-12" style="padding:0px;"><strong>Summary:</strong><br>
        <strong>${mObj.patient.messageCount}</strong> message(s) from the patient,<br>
        <strong>${mObj.provider.messageCount}</strong> message(s) from <strong>${totalClinicians}</strong> clinician(s) (incl. autoreply messages)</label>
        </section>
      `;
      
      // Start
      this.messagingResults += `
      <section><p class='col-sm-12 text-center' style='margin-top:1.5rem;margin-bottom:1.5rem;padding:0rem 3rem;'><small>[Start Transcript]</small></p></section>`;
    
      chatState.messages.forEach((m) => {
        const senderPersona = m.fromRecipient ? "Patient" : "Provider";
        const formattedSendTime = moment(m.sentOn).format();
        // Option to right-align patient messages
        const alignClass = m.fromRecipient ? "text-left" : "text-left text-bold";
        this.messagingResults += `<section><label class="col-sm-12 ${alignClass}" style="padding:0rem 3rem;"><span class="small italic">${senderPersona} (${m.senderDisplay}) on ${formattedSendTime}</span><br>${m.content}<hr></label></section>`;
      });

      // End
      this.messagingResults += `
      <section><p class='col-sm-12 text-center' style='margin-bottom:0rem;padding:0rem 3rem;'><small>[End Transcript]</small></p></section>`;

    } else {
      this.messagingResults += `
        <section>
        <label class="col-sm-12" style="padding:0px;"><strong>Summary:</strong><br>
        <small><em>No messages sent.</em></small></label>
        </section>
      `;
      
    }
    
    overallData.set('**messagingResults**', this.messagingResults);

    dataValues.set('overall', overallData);

    const phaseDataPromises = [];
    this.populatePhaseData(phaseDataPromises, patientState);


    const phaseDataResponses = await Promise.all(phaseDataPromises);
    const prescriptionPromises = [];
    phaseDataResponses.forEach(response => {
      const phaseMap = this.populateDataForTreatmentPhase(response.calculations, response.eventPhase);
      prescriptionPromises.push(this.setSentTacticsForPhase(phaseMap, response.eventPhase));
      dataValues.set(response.eventPhase, phaseMap);
    });

    await Promise.all(prescriptionPromises);
    return this.insertReportData(dataValues);
  }

  private calcMS(totalSeconds: number): string {
    // Round Ms
    totalSeconds = Math.round(totalSeconds);
    const s = Math.floor(totalSeconds % 60);
    const m = Math.floor((totalSeconds / 60));
    return `${m}m ${s}s`;
  }

  private populatePhaseData(promises, patientState){
    // go through each calendar event
    const weekEvents = [...patientState.weekEvents].sort((a,b) => moment(a.startDate).isAfter(moment(b.startDate)) ? 1 : -1);

    weekEvents.forEach((event, index) => {
      const match = new RegExp(/(\[.*\])\s+/).exec(event.title);
      const eventTitle = match ? event.title.replace(match[0], '') : event.title; // remove [username] from title

      const start = event.startDate.slice(0,10);
      const end = event.endDate.slice(0,10);

      const startDate = moment(start, "YYYY-MM-DD");
      const endDate = moment(end, "YYYY-MM-DD");
      //to here

      this.weeksMap.push({id: event.phaseName, label: eventTitle, start, end, index: index });

      if(moment(startDate).isBefore(moment.utc())) {
        promises.push(this.getPhaseCalculations(startDate, endDate, event.user.id, event.phaseName));
      }
    });
  }


  private populateDataForTreatmentPhase(data: CalculationsValueContainer, phaseName: string): Map<string, string> {
    const map = new Map<string, string>();
    const concatFields = {};
    this.reportDef.dataMapping.forEach(field => {
      const includeInWeek = field.includeInWeeks == undefined || field.includeInWeeks.find((s:string) => phaseName === s || phaseName.match(new RegExp(s, 'g')));
      if(includeInWeek) {
        const fieldValueDef = data.individualFields.assessmentValues.get(field.name);
        if(field.name === 'time-in-bed') { map.set(`**number-of-entries**`, fieldValueDef.numberOfOccurances?.toString() ?? '0')}
        switch(field.calcType) {
          case 'average':
            this.populateAverage(map, fieldValueDef, field);
            break;
          case 'indicated':
            this.populateIndicated(map, fieldValueDef, field);
            break;
          case 'concat':
            this.populateConcat(concatFields, fieldValueDef, field);
            break;
          case 'lookup':
            this.populateText(map, fieldValueDef, field);
            break;
          default:
            if(fieldValueDef.values.size > 0) {
              const entry = Array.from(fieldValueDef.values).find(e => e[1].rawValue !== null);
              map.set(`**${field.name}**`, (entry) ? entry[1].rawValue.toString() : null);
            } else{
              map.set(`**${field.name}**`, 'Not answered');
            }
            break;
        }
      } else {
        map.set(`**${field.name}**`, '');
      }
    });
    this.getPhaseStartEndData(map, phaseName);
    this.setTraumaDetails(map, phaseName, data.individualFields.assessmentValues.get('trauma'));
    this.setConcatFieldStrings(map, concatFields, phaseName);
    this.setMedicationChanges(map, phaseName);
    this.setClinicalSummary(map, phaseName);
    this.setCID(map, phaseName);
    this.setOSA(map, phaseName);
    this.handleLegacyPhqGad(map, phaseName);

    // Handle "Not administered" vs. "Not answered" for Intermountain; custom phq3, 9, (and 2)
    // TODO: remove intrm-test4
    
    const isPHQ9Administered = (this.patientGroups.filter(g => { return ["Intermountain", "intrm-test4"].includes(g.display); }));
    if (isPHQ9Administered.length > 0) {
      map.set(`**phq2-overall**`, map.get(`**phq2-overall**`).replace('Not answered', 'Not administered'));
    } else {
      map.set(`**phq3-overall**`, map.get(`**phq3-overall**`).replace('Not answered', 'Not administered'));
      map.set(`**phq9-overall**`, map.get(`**phq9-overall**`).replace('Not answered', 'Not administered'));
    }

    return map;
  }

  private handleLegacyPhqGad(map:Map<string, string>, phaseName:string) {
    const checkedPhases = ['baseline', 'weekFive', 'post'];
    const isCheck = checkedPhases.indexOf(phaseName)>-1 || phaseName.indexOf('followup') >-1
    if(isCheck){
      if(map.get(`**phq2-overall**`).indexOf('Not answered')==-1 || map.get(`**gad2-overall**`).indexOf('Not answered')==-1){
        map.set(`**phq-overall**`, map.get(`**phq-overall**`).replace('Not answered', 'Not administered'));
        map.set(`**gad7-overall**`, map.get(`**gad7-overall**`).replace('Not answered', 'Not administered'));
      }
      //check the oposite as well
      else if(map.get(`**phq-overall**`).indexOf('Not answered')==-1 || map.get(`**gad7-overall**`).indexOf('Not answered')==-1){
        map.set(`**phq2-overall**`, map.get(`**phq2-overall**`).replace('Not answered', 'Not administered'));
        map.set(`**gad2-overall**`, map.get(`**gad2-overall**`).replace('Not answered', 'Not administered'));
      }
      //when all are not answered, then we need to check whether the patient is legacy or 1.5
      else{
        if(this.isLegacy){
          map.set(`**phq2-overall**`, map.get(`**phq2-overall**`).replace('Not answered', 'Not administered'));
          map.set(`**gad2-overall**`, map.get(`**gad2-overall**`).replace('Not answered', 'Not administered'));
        }
        else{
          map.set(`**phq-overall**`, map.get(`**phq-overall**`).replace('Not answered', 'Not administered'));
          map.set(`**gad7-overall**`, map.get(`**gad7-overall**`).replace('Not answered', 'Not administered'));
        }
      }
    }
  }

  //to determine whether the patient is legacy or not based on their PHQ-8 definition
  private async isLegacyPhq(groups:SimpleInstance[]){
    const defs = await this.assessmentDefinitionService.getAllAsync({'Payload.name':AssessmentTemplate.phq8.name});
    if(defs.length==0) return false;
    return defs.findIndex(def => def.questions.length>0 && def.groups.filter(g => groups.map(gr => gr.display).indexOf(g)>-1).length>0)>-1
  }

  private setCID(map:Map<string, string>, phaseName:string) {
    if(phaseName === 'post') {
      map.set(`**clinical-interpretation**`, this.formatString(clinicalInterpretation));
    } else {
      map.set(`**clinical-interpretation**`, '');
    }

  }

  private populateText(map: Map<string, string>, fieldValueDef:any, field:any) {
    const assessmentTemplate = this.calcConfig.AssessmentDefinitions.find(def => def.name === field.name);
    const assessmentQuestionsDef = this.calcConfig.AssessmentQuestions.find(def => def.name === (assessmentTemplate as AssessmentFieldDefinition).assessmentType);
    if(fieldValueDef.values.size === 0) { return; }

    // Default answerText to null, then update based on fieldValueDef.average.rawValue
    let answerText = null;
    if (fieldValueDef.average.rawValue !== null) {
      const rawValue = Math.floor(fieldValueDef.average.rawValue);
      const questionDef = assessmentQuestionsDef.questions.find(q => q.name === field.name);
      answerText = questionDef.answerOptions?.find(a => a.value === rawValue)?.display;
    }

    if(field.label) {
      map.set(`**${field.name}**`, this.formatString(defaultFieldText, field.label, answerText ?? 'Not answered'));
    } else {
      map.set(`**${field.name}**`, answerText);
    }
  }

  private populateAverage(map: Map<string, string>, fieldValueDef:any, field:any) {
    let average = '';
    if(fieldValueDef.average?.rawValue == null){
      if(field.name === 'number-of-naps') {
        const isIndicated = map.get('**took-naps**').indexOf('Not answered') !== -1;
        if(isIndicated) {
          average = 'Not answered';
        } else {
          average = "0";
        }
      }else if(field.name === 'total-alcohol-intake') {
        const isIndicated = map.get('**had-alcoholic-drinks**').indexOf('Not answered') !== -1;
        if(isIndicated) {
          average = 'Not answered';
        } else {
          average = "0";
        }
      } else if (field.name === 'total-caffeine-intake') {
        const isIndicated = map.get('**had-caffeinated-drinks**').indexOf('Not answered') !== -1;
        if(isIndicated) {
          average = 'Not answered';
        } else {
          average = "0";
        }
      } else {
        const assessmentTemplate = this.calcConfig.AssessmentDefinitions.find(def => def.name === field.name);
        if(assessmentTemplate?.defaultValueForEmpty !== undefined) {
          average = assessmentTemplate.defaultValueForEmpty.toString();
        } else {
          average = 'Not answered';
        }
      }

    } else {
      average = this.getLongDescription(fieldValueDef.average.type, fieldValueDef.average.rawValue);
      if(field.includeStandardDeviation && fieldValueDef?.standardDeviation?.rawValue != null) {
        average += ` &plusmn; ${this.getLongDescription(fieldValueDef.standardDeviation.type, fieldValueDef.standardDeviation.rawValue)}`;
      }
    }
    if(field.label) {
      map.set(`**${field.name}**`, this.formatString(defaultFieldText, field.label, average));
    } else {
      map.set(`**${field.name}**`, average);
    }
  }

  private populateIndicated(map: Map<string, string>, fieldValueDef:any, field:any) {
    let indicated:string = ``;
    if(fieldValueDef.numberOfOccurances == null) {
      indicated = 'Not answered';
    } else if(fieldValueDef.numberOfOccurances === 0) {
      indicated = 'No';
    } else {
      indicated = 'Yes';
    }
    if(field.label) {
      map.set(`**${field.name}**`, this.formatString(defaultFieldText, field.label, indicated));
    } else {
      map.set(`**${field.name}**`, indicated);
    }
  }

  private populateConcat(concatFields:any, fieldValueDef:FieldValueDefinitionBase, field:any) {
    const assessmentTemplate = this.calcConfig.AssessmentDefinitions.find(def => def.name === field.name);
    const assessmentQuestionsDef = this.calcConfig.AssessmentQuestions.find(def => def.name === (assessmentTemplate as AssessmentFieldDefinition).assessmentType);
    if(!concatFields[field.groupName]) { concatFields[field.groupName] = '' };
    if(fieldValueDef.values.size === 0) { return; }

    const entry = Array.from(fieldValueDef.values).find(e => e[1].rawValue !== null);
    if(!entry) {return;}
    const rawValue = entry[1].rawValue;
    if(rawValue == null) { return;}
    const questionDef = assessmentQuestionsDef.questions.find(q => q.name === field.name);
    const answerText = questionDef.answerOptions?.find(a => a.value === rawValue)?.display;
    if(!concatFields[field.groupName]) { concatFields[field.groupName] = '' };
    if(assessmentTemplate.unit === UnitType.YesNo && fieldValueDef.numberOfOccurances > 0) {
      concatFields[field.groupName] += `<li>${questionDef.questionEmphasis}</li>`;
    } else if(assessmentTemplate.unit === UnitType.Text) {
      concatFields[field.groupName] += `<li>${rawValue}</li>`;
    } else if (answerText?.toUpperCase() !== 'NONE' && answerText?.toUpperCase() !== 'NO' && answerText?.toUpperCase() !== 'FULLY AWAKE') {
      concatFields[field.groupName] += `<li>${answerText}</li>`;
    }
  }

  private setConcatFieldStrings(map, fieldDict:any, phaseName) {
    Object.keys(fieldDict).forEach(key => {
      let stringValue:string = fieldDict[key];
      if(stringValue === '' && key === 'side-effects') { stringValue = 'Not administered'; }
      if(key === 'sleep-goals' && phaseName === 'baseline' && stringValue.length>0) { stringValue = this.formatString(sleepGoalsText, stringValue)}
      if(key === 'sleep-challenges' && phaseName === 'baseline' && stringValue.length>0) { stringValue = this.formatString(patientChallengesText, stringValue)}
      if(stringValue.length>0){
        map.set(`**${key}**`, `<ul class='pl-0'>${stringValue}</ul>`);
      }
      else{
        map.set(`**${key}**`, '');  
      }
    });
  }

  private setClinicalSummary(map:Map<string, string>, phaseName:string) {
    if(['weekOne', 'weekTwo', 'weekThree', 'weekFour'].indexOf(phaseName) !== -1) {
      map.set('**clinical-summary**', this.formatString(clinicalSummaryText, moment().format(this.DATE_FORMAT)));
    } else {
      map.set('**clinical-summary**', '');
    }
  }

  private setTraumaDetails(map: Map<string, string>, phaseName: string, traumaValue: AssessmentValueDefinition) {
    let labelString = '';
    let valueString = '';
    const showTraumaWeeks = ['baseline', 'weekFive', 'post', 'followup', 'followup1', 'followup2', 'followup3'];
    const isTrauma = (traumaValue.numberOfOccurances != null && traumaValue.numberOfOccurances > 0);
    const showTraumaQuestion = showTraumaWeeks.indexOf(phaseName) !== -1;

    if(isTrauma && phaseName === 'baseline') {
      this.traumaBaseline = true;
    }

    if(showTraumaQuestion && isTrauma) {
      labelString = `PTSD Checklist for DSM-5:`;
      valueString = map.get('**pcl5-overall**')
      if(valueString === map.get('**pcPtsd-overall**')){ //both not answered
        labelString = 'PTSD questionnaire';
        valueString = 'Not answered';  
      }
      else{
        try{
          if(parseInt(map.get('**pcPtsd-overall**'))>=0){
            labelString = `PC-PTSD-5`;
            valueString = map.get('**pcPtsd-overall**')
          }
        }catch(err){
          console.log(err);
          //no **pcPtsd-overall**
        }
      }
    } else if(showTraumaQuestion && !isTrauma) {
      labelString = 'PTSD questionnaire';
      valueString = 'Not administered';
    } else if(!showTraumaQuestion && this.traumaBaseline) {
      labelString = 'PC-PTSD-5:';
      valueString = map.get('**pcPtsd-overall**');
    }
    else {
      labelString = '';
      valueString = '';
    }
    map.set(`**trauma-details**`, this.formatString(defaultFieldText, labelString, valueString));
  }

  private async setSentTacticsForPhase(map, phaseName:string): Promise<string> {
    const prescriptions = await this.prescriptionService.getAllAsync(
      {
        $and: [
          {'Payload.phaseName':phaseName},
          {'Payload.patient.id':this.patientId}
        ]
    });
    let prescriptionString = '';
    let notesString = '';
    if(prescriptions.length > 0) {
      for (const prescription of prescriptions) {
        let bedTime = prescription.bedTime ? prescription.bedTime : 'No bedtime sent';
        let riseTime = prescription.riseTime ? prescription.riseTime : 'No rise time sent';
        const sentTacticsString = this.getSentTacticsFromPrescription(prescription);
        const lastCallLoggedOnDate = new Date(prescription?.lastCallLoggedOn);
        let lastCallLoggedOn = 'None logged';
        if (!isNaN(lastCallLoggedOnDate.getTime())) {
          // Adjust to the caller's offset (at the time of the date, not now)
          lastCallLoggedOnDate.setUTCMinutes(new Date(lastCallLoggedOnDate.getTime()).getTimezoneOffset());
          lastCallLoggedOn = lastCallLoggedOnDate.toLocaleDateString();
        }
        prescriptionString += this.formatString(tacticText, moment(prescription.__i.auditing.lastUpdatedOn).format(this.DATE_FORMAT), bedTime, riseTime, sentTacticsString, lastCallLoggedOn);

        if (!prescription.note) {
          notesString += `<p>N/A</p>`;
        } else {
          notesString += `<p>${prescription.note}</p>`;

          // Print notes signatures & timestamp if available  
          if (!prescription.notesLastSignedOn || !prescription.notesLastSignedBy) {
            ;
            // TODO: after an appropriate transition period for existing clinicans, uncomment the following line:
            // notesString += `<small class="d-block">Last signed: N/A</small>`;
          } else {
            // Get user for firstName, lastName
            const _u = await this.userFuncService.getUser(prescription.notesLastSignedBy).toPromise();
            const _notesLastSignedSignature = "/s/ " +  (_u?.profile?.name?.First + " " + _u?.profile?.name?.Last + " (" + _u.UserId.split('-').pop() + ")").trim(); 
            // notesString += `<small>Last signed: ${prescription.notesLastSignedOn} ${_notesLastSignedSignature}</small>`;
            notesString += `<small class="d-block">${_notesLastSignedSignature}</small>`;
            notesString += `<small class="d-block">${prescription.notesLastSignedOn}</small>`;
          }
        }

        // Not needed as additionalInfo is currently commented
        // prescriptionString += additionalInfo;

        map.set(`**prescription-info**`, prescriptionString);
        map.set(`**provider-notes**`, this.formatString(notesText, notesString));
      };

    } else {
      map.set(`**prescription-info**`, '');
      map.set(`**provider-notes**`, '');
    }

    return phaseName;
  }

  private getSentTacticsFromPrescription(prescription: SleepPrescription):string {
    let tacticString = '';
    prescription.patientFlags.forEach(flag => {
      if(!!flag.isSelected) {
        tacticString += `${flag.label}, `;
      }
    });
    if(tacticString.length === 0) { tacticString = 'No tactics sent' }
    else { tacticString = tacticString.slice(0, -2);}
    return tacticString;
  }



  private getPhaseStartEndData(map, phaseName: string){
    const week = this.weeksMap.find(w => w.id === phaseName);
    if(week) {
      map.set(`**startDate**`, moment(week.start).format(this.DATE_FORMAT));
      map.set(`**endDate**`, moment(week.end).format(this.DATE_FORMAT));
    }
  }

  private setMedicationChanges(map:Map<string, string>, phaseName:string) {
    if(phaseName !== 'baseline')
    {
      const medsAdded = map.get('**medication-use-add**');
      const medsChanged = map.get('**medication-use-change**');
      if(medsAdded === 'Not answered' && medsAdded === 'Not answered') {
        map.set('**medications-changed**', this.formatString(medicationUseText, 'Not Answered'));
        map.set('**medications-details**', '');
        return;
      }
      const hasChanges = (medsAdded === 'Yes' || medsChanged === 'Yes');
      const hasChangesText = hasChanges ? 'Yes' : 'No';
      map.set('**medications-changed**', this.formatString(medicationUseText, hasChangesText));
      if(hasChanges) {
        const medsAddedText = (map.get('**medication-use-what**') != null
                                    && map.get('**medication-use-what**') !== 'Not answered'
                                        ? map.get('**medication-use-what**') : 'None');
        const medsChangedText = (map.get('**medication-use-change-what**') != null
                                    && map.get('**medication-use-change-what**') !== 'Not answered'
                                        ? map.get('**medication-use-change-what**') : 'None');
        map.set('**medications-details**', this.formatString(medicationsDetailsText, medsAddedText, medsChangedText));
      } else {
        map.set('**medications-details**', '');
      }
    } else {
      map.set('**medications-changed**', '');
      map.set('**medications-details**', '');
    }

  }

  private async getPhaseCalculations(startDate: moment.Moment, endDate: moment.Moment, userId: string, eventPhase:string): Promise<any> {
    const calculationOptions = {
      averageType: 'mean',
      includeStandardDeviation: true
    }
    const calculations = await this.calculationService.getCalculationsFromAPI(startDate, endDate.add(1, 'd'), this.reportDef.dataMapping.map(r => r.name), [userId], false, [eventPhase], calculationOptions);
    return {calculations, eventPhase};
  }

  private setOSA(map:Map<string, string>, phaseName: string) {
    const osaConfig = this.reportDef.dataMapping.find(a => a.name === 'osa-diagnosed');
    if (phaseName === 'post') {
      map.set('**osa**', this.formatString(defaultFieldText, 'Reported a diagnosis of OSA or suspected OSA', this.osaBaseline));
      return;
    }
    if(osaConfig.includeInWeeks.indexOf(phaseName) === -1) {
      map.set('**osa**', '');
      return;
    }


    const osaDiagnosed = map.get('**osa-diagnosed**');
    if(osaDiagnosed === 'Yes') {
      map.set('**osa**', this.formatString(defaultFieldText, 'Reported a diagnosis of OSA or suspected OSA', 'Yes'));
      this.osaBaseline = 'Yes';
      return;
    } else if (osaDiagnosed == '' || osaDiagnosed === 'Not answered') {
      this.osaBaseline = 'Not answered';
      map.set('**osa**', this.formatString(defaultFieldText, 'Reported a diagnosis of OSA or suspected OSA', 'Not answered'));
      return;
    }
    const osaDetailsFalse = this.getOsaDetailsFalse(map);
    const countSpecificPositiveAnswers: Array<string | number> = osaDetailsFalse.filter(ans => ans.name !== '**osa-gasping**')
    .filter(ans => ans.name !== '**osa-stop-breathing**')
    .filter(ans => ans.name !== '**osa-snore-loudly**')
    .filter(ans => ans.name !== '**osa-diagnosed**')
    .filter(ans => ans.value === true || ans.value === 1);

    // Answers to specific questions

    // a
    const osaGasping = osaDetailsFalse.find(ans => ans.name === '**osa-gasping**');
    // b
    const osaStopBreathing = osaDetailsFalse.find(ans => ans.name === '**osa-stop-breathing**');
    // c
    const osaSnoreLoudly = osaDetailsFalse.find(ans => ans.name === '**osa-snore-loudly**');
    // d
    const osaDryMouth = osaDetailsFalse.find(ans => ans.name === '**osa-dry-mouth**');
    // h
    const neckSize = osaDetailsFalse.find(ans => ans.name === '**osa-neck-size**');
    // g
    const sleepy = osaDetailsFalse.find(ans => ans.name === '**osa-sleepy**')

    // (a or b) || (c & g)
    let flag = false;
    if ((osaGasping.value || osaStopBreathing.value) || (osaSnoreLoudly.value && sleepy.value)) {
      flag = true;
    }

    // c & 2x{d|e|f|g|h}
    if (osaSnoreLoudly.value && (countSpecificPositiveAnswers.length >= 2)) {
      flag = true;
    } else {
      flag = false;
    }
    this.osaBaseline = flag ? 'Yes': 'No';
    map.set('**osa**', this.formatString(defaultFieldText, 'Reported a diagnosis of OSA or suspected OSA', flag ? 'Yes' : 'No'));
  }

  private getOsaDetailsFalse(map:Map<string, string>): any {
    const uniqueIdArr: Array<string> = [
      '**osa-gasping**',
      '**osa-stop-breathing**',
      '**osa-snore-loudly**',
      '**osa-dry-mouth**',
      '**osa-congestion-headaches**',
      '**osa-blood-pressure**',
      '**osa-sleepy**',
      '**osa-neck-size**',
    ];

    const answers = [];

    uniqueIdArr.forEach(uId => {
      answers.push({name: uId, value: map.get(uId)});
    });
    return answers;
  }


  private insertReportData(data: Map<string, Map<string, string>>): ReportViewer {
    const viewer = new ReportViewer();
    viewer.pages = [];
    viewer.title = 'Intervention Report';
    this.reportDef.pageTemplates.forEach(template => {
      if(template.name !== 'treatmentWeeks') {
        const overallData = data.get('overall');
        this.replaceTemplateContentWithData(overallData, viewer, template);
      } else {
        data.forEach((phaseData, phaseName) => {
          if(phaseName !== 'overall')
            this.replaceTemplateContentWithData(phaseData, viewer, template, phaseName);
        });
      }
    });
    return viewer;
  }

  private replaceTemplateContentWithData(data:Map<string, string>, viewer: ReportViewer, template:any, phaseName?: string){
    let content:string = template.content;
    data.forEach((value, id) => {
      content = content.replace(id, value);
    });
    const week = this.weeksMap.find(p => p.id === phaseName);
    const phaseLabel = week?.label ?? 'Overall';
    content = content.replace('**weekLabel**', phaseLabel.toUpperCase());
    let label = '';
    
    if(phaseName) {
      label = this.weeksMap.find(w => w.id === phaseName)?.label;
    } else {
      label = template.label;
    }
    
    viewer.pages.push({content:this.domSanitizer.bypassSecurityTrustHtml(content), name: label, required: template.required} );
  }

  private formatString(template: string, ...args): string {
    args.forEach((arg, index) => {
      template = template.replace(new RegExp('\\{' + index + '\\}', 'g'), arg);
    });
    return template;
  }

  getShortDescription({key, abbreviation, description}, value: any, ): string {
    return this.getDescription(key, value, abbreviation, description);
  }

  getLongDescription({key, abbreviation, description}, value: any, ): string {
    return this.getDescription(key, value, abbreviation, description);
  }

  private getDescription(key: any, value: number|string|moment.Moment|boolean, template: string, description: string) {
    if (value == null || Object.is(value,Number.NaN) || template == null) { return '-'; }
    let returnValue = '';
    switch (key) {
      case 'yesno':
        returnValue =  (value as boolean) === true ? 'Yes' : 'No';
        break;
      case 'number':
        returnValue = this.formatString(description, value.toString());
        break;
      case 'text':
        returnValue = this.formatString(description, value);
        break;
      case 'hours':
      case 'minutes':
        const hrsMins = this.getHoursMinutesFromNumber((value as number), key === 'hours');
        const hoursString = `${hrsMins.hours}h `;
        const minutesString = `${hrsMins.minutes}m`;
        returnValue = (hrsMins) ? this.formatString(description, hoursString, minutesString) : null;
        break;
      case 'time':
      case 'morningtime':
      case 'eveningtime':
        const hoursAndMinutes = this.getHoursMinutesFromNumber(value as number);
        returnValue = this.formatString(description,
                                          (hoursAndMinutes.hours > 9) ? hoursAndMinutes.hours.toString() : '0' + hoursAndMinutes.hours.toString(),
                                          (hoursAndMinutes.minutes > 9) ? hoursAndMinutes.minutes.toString() : '0' + hoursAndMinutes.minutes.toString());
        break;
      case 'date':
        const dateValue = (value as moment.Moment);
        returnValue = dateValue.format(dateValue.toLocaleString());
        break;
      case 'percent':
        returnValue = this.formatString(description, Math.round((value as number) * 100).toString());
        break;
      default:
        returnValue = value.toString();
        break;
    }
    return returnValue;
  }

  private getHoursMinutesFromNumber(number: number, isHours = false): any {
    if (number == null) {
      return null;
    }
    let hours = Math.floor(number / 60);
    // Question for Dustin - What was the intention of this?
    if(hours >= 24) {
      hours -= 24;
    }
    const minutes = Math.round(number % 60);
    return { hours, minutes };
  }

  //cleans up variables, will be called by component on destroy
  clear() {
    this.reportDef = undefined;
    this.weeksMap = [];
    this.patientId = '';
    this.traumaBaseline = false;
    this.osaBaseline = '';
    this.isLegacy = false;
    this.timeSessionResults = '';
    this.messagingResults = '';
  }
}
