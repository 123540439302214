export const TBIInsomniaTreatmentAcceptabilityDef = {
  name: 'tbi-insomnia-treatment-acceptability',
  description: 'Insomnia Treatment Acceptability Scale',
  instructions: 'Two treatment methods commonly used for insomnia problems are described below. Please read the description of each method and answer each question as it would apply to your insomnia problem. For each question, choose a value where your personal rating falls. Please consider the line to represent your own personal range. Try to use the whole scale, rather than simply choosing your rating at one end or the other.',
  /*
  Note: the questions property must be empty here (but available in the database) or this assessment will be made available for all patients

  questions: [
    {
      "id": 0,
      "questionText": "Treatment 1: in-person CBT-I. This is a non-drug treatment method aimed at teaching patients self-management skills to overcome insomnia. The behavioral component provides specific guidelines for changing poor sleep habits and for regulating sleep schedules. Participants may also be encouraged to use relaxation techniques or guided to examine and modify their beliefs and attitudes about sleep that may perpetuate their insomnia. Education about health habits that may affect sleep (e.g. diet, exercise, and substance use) is also provided. This treatment is delivered over 6 weeks over in-person visits (face-to-face or virtual) at the clinician's office. The visits can last up to 1 hour. Participants can reach their assigned clinician by telephone as needed between scheduled in-person or telephone appointments. Please complete all your ratings for this treatment method before proceeding to read the description of the second treatment method.",
      "questionEmphasis": "On a scale from 0 (not at all acceptable) to 10 (very acceptable), how acceptable would you consider this treatment for your insomnia?",
      "questionType": "select",
      "uniqueAnswerId": "ITA-T1_ACCEPTIBLE_YOU",
      "autofill": false,
      "answerOptions": [
        {
          "display": "0",
          "value": 0
        },
        {
          "display": "1",
          "value": 1
        },
        {
          "display": "2",
          "value": 2
        },
        {
          "display": "3",
          "value": 3
        },
        {
          "display": "4",
          "value": 4
        },
        {
          "display": "5",
          "value": 5
        },
        {
          "display": "6",
          "value": 6
        },
        {
          "display": "7",
          "value": 7
        },
        {
          "display": "8",
          "value": 8
        },
        {
          "display": "9",
          "value": 9
        },
        {
          "display": "10",
          "value": 10
        }
      ],
      "conditionalQuestions": []
    },
    {
      "id": 1,
      "questionText": "",
      "questionEmphasis": "On a scale from 0 (not at all acceptable) to 10 (very acceptable), how acceptable would you consider this treatment for other people with insomnia?",
      "questionType": "select",
      "uniqueAnswerId": "ITA-T1_ACCEPTIBLE_OTHERS",
      "autofill": false,
      "answerOptions": [
        {
          "display": "0",
          "value": 0
        },
        {
          "display": "1",
          "value": 1
        },
        {
          "display": "2",
          "value": 2
        },
        {
          "display": "3",
          "value": 3
        },
        {
          "display": "4",
          "value": 4
        },
        {
          "display": "5",
          "value": 5
        },
        {
          "display": "6",
          "value": 6
        },
        {
          "display": "7",
          "value": 7
        },
        {
          "display": "8",
          "value": 8
        },
        {
          "display": "9",
          "value": 9
        },
        {
          "display": "10",
          "value": 10
        }
      ],
      "conditionalQuestions": []
    },
    {
      "id": 2,
      "questionText": "",
      "questionEmphasis": "On a scale from 0 (not at all willing) to 10 (very willing), how willing would you be to adhere to this treatment regimen if recommended for your insomnia?",
      "questionType": "select",
      "uniqueAnswerId": "ITA-T1_WILLING",
      "autofill": false,
      "answerOptions": [
        {
          "display": "0",
          "value": 0
        },
        {
          "display": "1",
          "value": 1
        },
        {
          "display": "2",
          "value": 2
        },
        {
          "display": "3",
          "value": 3
        },
        {
          "display": "4",
          "value": 4
        },
        {
          "display": "5",
          "value": 5
        },
        {
          "display": "6",
          "value": 6
        },
        {
          "display": "7",
          "value": 7
        },
        {
          "display": "8",
          "value": 8
        },
        {
          "display": "9",
          "value": 9
        },
        {
          "display": "10",
          "value": 10
        }
      ],
      "conditionalQuestions": []
    },
    {
      "id": 3,
      "questionText": "On a scale from 0 (not at all suitable) to 10 (very suitable), how suitable do you think this treatment would be for treating:",
      "questionEmphasis": "Difficulty falling asleep?",
      "questionType": "select",
      "uniqueAnswerId": "ITA-T1_SUITABLE_FALLING_ASLEEP",
      "autofill": false,
      "answerOptions": [
        {
          "display": "0",
          "value": 0
        },
        {
          "display": "1",
          "value": 1
        },
        {
          "display": "2",
          "value": 2
        },
        {
          "display": "3",
          "value": 3
        },
        {
          "display": "4",
          "value": 4
        },
        {
          "display": "5",
          "value": 5
        },
        {
          "display": "6",
          "value": 6
        },
        {
          "display": "7",
          "value": 7
        },
        {
          "display": "8",
          "value": 8
        },
        {
          "display": "9",
          "value": 9
        },
        {
          "display": "10",
          "value": 10
        }
      ],
      "conditionalQuestions": []
    },
    {
      "id": 4,
      "questionText": "On a scale from 0 (not at all suitable) to 10 (very suitable), how suitable do you think this treatment would be for treating:",
      "questionEmphasis": "Difficulty staying asleep?",
      "questionType": "select",
      "uniqueAnswerId": "ITA-T1_SUITABLE_STAYING_ASLEEP",
      "autofill": false,
      "answerOptions": [
        {
          "display": "0",
          "value": 0
        },
        {
          "display": "1",
          "value": 1
        },
        {
          "display": "2",
          "value": 2
        },
        {
          "display": "3",
          "value": 3
        },
        {
          "display": "4",
          "value": 4
        },
        {
          "display": "5",
          "value": 5
        },
        {
          "display": "6",
          "value": 6
        },
        {
          "display": "7",
          "value": 7
        },
        {
          "display": "8",
          "value": 8
        },
        {
          "display": "9",
          "value": 9
        },
        {
          "display": "10",
          "value": 10
        }
      ],
      "conditionalQuestions": []
    },
    {
      "id": 5,
      "questionText": "On a scale from 0 (not at all effective) to 10 (very effective), how effective do you believe this treatment would be:",
      "questionEmphasis": "In the short term?",
      "questionType": "select",
      "uniqueAnswerId": "ITA-T1_EFFECTIVE_SHORT",
      "autofill": false,
      "answerOptions": [
        {
          "display": "0",
          "value": 0
        },
        {
          "display": "1",
          "value": 1
        },
        {
          "display": "2",
          "value": 2
        },
        {
          "display": "3",
          "value": 3
        },
        {
          "display": "4",
          "value": 4
        },
        {
          "display": "5",
          "value": 5
        },
        {
          "display": "6",
          "value": 6
        },
        {
          "display": "7",
          "value": 7
        },
        {
          "display": "8",
          "value": 8
        },
        {
          "display": "9",
          "value": 9
        },
        {
          "display": "10",
          "value": 10
        }
      ],
      "conditionalQuestions": []
    },
    {
      "id": 6,
      "questionText": "On a scale from 0 (not at all effective) to 10 (very effective), how effective do you believe this treatment would be:",
      "questionEmphasis": "For producing a permanent cure?",
      "questionType": "select",
      "uniqueAnswerId": "ITA-T1_EFFECTIVE_PERMANENT",
      "autofill": false,
      "answerOptions": [
        {
          "display": "0",
          "value": 0
        },
        {
          "display": "1",
          "value": 1
        },
        {
          "display": "2",
          "value": 2
        },
        {
          "display": "3",
          "value": 3
        },
        {
          "display": "4",
          "value": 4
        },
        {
          "display": "5",
          "value": 5
        },
        {
          "display": "6",
          "value": 6
        },
        {
          "display": "7",
          "value": 7
        },
        {
          "display": "8",
          "value": 8
        },
        {
          "display": "9",
          "value": 9
        },
        {
          "display": "10",
          "value": 10
        }
      ],
      "conditionalQuestions": []
    },
    {
      "id": 7,
      "questionText": "On a scale from 0 (not at all effective) to 10 (very effective), how effective do you believe this treatment would be:",
      "questionEmphasis": "In addition to improving sleep, for improving other aspects of your daytime functioning (e.g. alertness, performance, mood?)",
      "questionType": "select",
      "uniqueAnswerId": "ITA-T1_EFFECTIVE_OTHER",
      "autofill": false,
      "answerOptions": [
        {
          "display": "0",
          "value": 0
        },
        {
          "display": "1",
          "value": 1
        },
        {
          "display": "2",
          "value": 2
        },
        {
          "display": "3",
          "value": 3
        },
        {
          "display": "4",
          "value": 4
        },
        {
          "display": "5",
          "value": 5
        },
        {
          "display": "6",
          "value": 6
        },
        {
          "display": "7",
          "value": 7
        },
        {
          "display": "8",
          "value": 8
        },
        {
          "display": "9",
          "value": 9
        },
        {
          "display": "10",
          "value": 10
        }
      ],
      "conditionalQuestions": []
    },
    {
      "id": 8,
      "questionText": "On a scale from 0 (very strong side effects) to 10 (no side effects), to what extent:",
      "questionEmphasis": "Would this treatment produce side effects?",
      "questionType": "select",
      "uniqueAnswerId": "ITA-T1_SIDE_EFFECTS",
      "autofill": false,
      "answerOptions": [
        {
          "display": "0",
          "value": 0
        },
        {
          "display": "1",
          "value": 1
        },
        {
          "display": "2",
          "value": 2
        },
        {
          "display": "3",
          "value": 3
        },
        {
          "display": "4",
          "value": 4
        },
        {
          "display": "5",
          "value": 5
        },
        {
          "display": "6",
          "value": 6
        },
        {
          "display": "7",
          "value": 7
        },
        {
          "display": "8",
          "value": 8
        },
        {
          "display": "9",
          "value": 9
        },
        {
          "display": "10",
          "value": 10
        }
      ],
      "conditionalQuestions": []
    },
    {
      "id": 9,
      "questionText": "Treatment 2: CBT-I via COAST. This treatment consists of using an \"app\" from the participants' smartphone or tablet to learn how they can modify their sleep habits to overcome insomnia. Participants are paired with a clinician who will monitor a participant's sleep habits, changes to sleep habits, and progress in using healthy sleep practices based on recommendations provided by the sleep coach. This treatment is delivered over 6 weeks, with no scheduled office visit, unless it is determined by the participant or clinician that a visit is necessary. Clinicians will monitor participants and send reminders as needed to participants to encourage them to use healthy sleep habits to overcome insomnia. Participants can also reach their assigned coach via a secure messaging system provided with the \"app\" at any time.",
      "questionEmphasis": "On a scale from 0 (not at all acceptable) to 10 (very acceptable), how acceptable would you consider this treatment for your insomnia?",
      "questionType": "select",
      "uniqueAnswerId": "ITA-T2_ACCEPTIBLE_YOU",
      "autofill": false,
      "answerOptions": [
        {
          "display": "0",
          "value": 0
        },
        {
          "display": "1",
          "value": 1
        },
        {
          "display": "2",
          "value": 2
        },
        {
          "display": "3",
          "value": 3
        },
        {
          "display": "4",
          "value": 4
        },
        {
          "display": "5",
          "value": 5
        },
        {
          "display": "6",
          "value": 6
        },
        {
          "display": "7",
          "value": 7
        },
        {
          "display": "8",
          "value": 8
        },
        {
          "display": "9",
          "value": 9
        },
        {
          "display": "10",
          "value": 10
        }
      ],
      "conditionalQuestions": []
    },
    {
      "id": 10,
      "questionText": "",
      "questionEmphasis": "On a scale from 0 (not at all acceptable) to 10 (very acceptable), how acceptable would you consider this treatment for other people with insomnia?",
      "questionType": "select",
      "uniqueAnswerId": "ITA-T2_ACCEPTIBLE_OTHERS",
      "autofill": false,
      "answerOptions": [
        {
          "display": "0",
          "value": 0
        },
        {
          "display": "1",
          "value": 1
        },
        {
          "display": "2",
          "value": 2
        },
        {
          "display": "3",
          "value": 3
        },
        {
          "display": "4",
          "value": 4
        },
        {
          "display": "5",
          "value": 5
        },
        {
          "display": "6",
          "value": 6
        },
        {
          "display": "7",
          "value": 7
        },
        {
          "display": "8",
          "value": 8
        },
        {
          "display": "9",
          "value": 9
        },
        {
          "display": "10",
          "value": 10
        }
      ],
      "conditionalQuestions": []
    },
    {
      "id": 11,
      "questionText": "",
      "questionEmphasis": "On a scale from 0 (not at all willing) to 10 (very willing), how willing would you be to adhere to this treatment regimen if recommended for your insomnia?",
      "questionType": "select",
      "uniqueAnswerId": "ITA-T2_WILLING",
      "autofill": false,
      "answerOptions": [
        {
          "display": "0",
          "value": 0
        },
        {
          "display": "1",
          "value": 1
        },
        {
          "display": "2",
          "value": 2
        },
        {
          "display": "3",
          "value": 3
        },
        {
          "display": "4",
          "value": 4
        },
        {
          "display": "5",
          "value": 5
        },
        {
          "display": "6",
          "value": 6
        },
        {
          "display": "7",
          "value": 7
        },
        {
          "display": "8",
          "value": 8
        },
        {
          "display": "9",
          "value": 9
        },
        {
          "display": "10",
          "value": 10
        }
      ],
      "conditionalQuestions": []
    },
    {
      "id": 12,
      "questionText": "On a scale from 0 (not at all suitable) to 10 (very suitable), how suitable do you think this treatment would be for treating:",
      "questionEmphasis": "Difficulty falling asleep?",
      "questionType": "select",
      "uniqueAnswerId": "ITA-T2_SUITABLE_FALLING_ASLEEP",
      "autofill": false,
      "answerOptions": [
        {
          "display": "0",
          "value": 0
        },
        {
          "display": "1",
          "value": 1
        },
        {
          "display": "2",
          "value": 2
        },
        {
          "display": "3",
          "value": 3
        },
        {
          "display": "4",
          "value": 4
        },
        {
          "display": "5",
          "value": 5
        },
        {
          "display": "6",
          "value": 6
        },
        {
          "display": "7",
          "value": 7
        },
        {
          "display": "8",
          "value": 8
        },
        {
          "display": "9",
          "value": 9
        },
        {
          "display": "10",
          "value": 10
        }
      ],
      "conditionalQuestions": []
    },
    {
      "id": 13,
      "questionText": "On a scale from 0 (not at all suitable) to 10 (very suitable), how suitable do you think this treatment would be for treating:",
      "questionEmphasis": "Difficulty staying asleep?",
      "questionType": "select",
      "uniqueAnswerId": "ITA-T2_SUITABLE_STAYING_ASLEEP",
      "autofill": false,
      "answerOptions": [
        {
          "display": "0",
          "value": 0
        },
        {
          "display": "1",
          "value": 1
        },
        {
          "display": "2",
          "value": 2
        },
        {
          "display": "3",
          "value": 3
        },
        {
          "display": "4",
          "value": 4
        },
        {
          "display": "5",
          "value": 5
        },
        {
          "display": "6",
          "value": 6
        },
        {
          "display": "7",
          "value": 7
        },
        {
          "display": "8",
          "value": 8
        },
        {
          "display": "9",
          "value": 9
        },
        {
          "display": "10",
          "value": 10
        }
      ],
      "conditionalQuestions": []
    },
    {
      "id": 14,
      "questionText": "On a scale from 0 (not at all effective) to 10 (very effective), how effective do you believe this treatment would be:",
      "questionEmphasis": "In the short term?",
      "questionType": "select",
      "uniqueAnswerId": "ITA-T2_EFFECTIVE_SHORT",
      "autofill": false,
      "answerOptions": [
        {
          "display": "0",
          "value": 0
        },
        {
          "display": "1",
          "value": 1
        },
        {
          "display": "2",
          "value": 2
        },
        {
          "display": "3",
          "value": 3
        },
        {
          "display": "4",
          "value": 4
        },
        {
          "display": "5",
          "value": 5
        },
        {
          "display": "6",
          "value": 6
        },
        {
          "display": "7",
          "value": 7
        },
        {
          "display": "8",
          "value": 8
        },
        {
          "display": "9",
          "value": 9
        },
        {
          "display": "10",
          "value": 10
        }
      ],
      "conditionalQuestions": []
    },
    {
      "id": 15,
      "questionText": "On a scale from 0 (not at all effective) to 10 (very effective), how effective do you believe this treatment would be:",
      "questionEmphasis": "For producing a permanent cure?",
      "questionType": "select",
      "uniqueAnswerId": "ITA-T2_EFFECTIVE_PERMANENT",
      "autofill": false,
      "answerOptions": [
        {
          "display": "0",
          "value": 0
        },
        {
          "display": "1",
          "value": 1
        },
        {
          "display": "2",
          "value": 2
        },
        {
          "display": "3",
          "value": 3
        },
        {
          "display": "4",
          "value": 4
        },
        {
          "display": "5",
          "value": 5
        },
        {
          "display": "6",
          "value": 6
        },
        {
          "display": "7",
          "value": 7
        },
        {
          "display": "8",
          "value": 8
        },
        {
          "display": "9",
          "value": 9
        },
        {
          "display": "10",
          "value": 10
        }
      ],
      "conditionalQuestions": []
    },
    {
      "id": 16,
      "questionText": "On a scale from 0 (not at all effective) to 10 (very effective), how effective do you believe this treatment would be:",
      "questionEmphasis": "In addition to improving sleep, for improving other aspects of your daytime functioning (e.g. alertness, performance, mood?)",
      "questionType": "select",
      "uniqueAnswerId": "ITA-T2_EFFECTIVE_OTHER",
      "autofill": false,
      "answerOptions": [
        {
          "display": "0",
          "value": 0
        },
        {
          "display": "1",
          "value": 1
        },
        {
          "display": "2",
          "value": 2
        },
        {
          "display": "3",
          "value": 3
        },
        {
          "display": "4",
          "value": 4
        },
        {
          "display": "5",
          "value": 5
        },
        {
          "display": "6",
          "value": 6
        },
        {
          "display": "7",
          "value": 7
        },
        {
          "display": "8",
          "value": 8
        },
        {
          "display": "9",
          "value": 9
        },
        {
          "display": "10",
          "value": 10
        }
      ],
      "conditionalQuestions": []
    },
    {
      "id": 17,
      "questionText": "On a scale from 0 (very strong side effects) to 10 (no side effects), to what extent:",
      "questionEmphasis": "Would this treatment produce side effects?",
      "questionType": "select",
      "uniqueAnswerId": "ITA-T2_SIDE_EFFECTS",
      "autofill": false,
      "answerOptions": [
        {
          "display": "0",
          "value": 0
        },
        {
          "display": "1",
          "value": 1
        },
        {
          "display": "2",
          "value": 2
        },
        {
          "display": "3",
          "value": 3
        },
        {
          "display": "4",
          "value": 4
        },
        {
          "display": "5",
          "value": 5
        },
        {
          "display": "6",
          "value": 6
        },
        {
          "display": "7",
          "value": 7
        },
        {
          "display": "8",
          "value": 8
        },
        {
          "display": "9",
          "value": 9
        },
        {
          "display": "10",
          "value": 10
        }
      ],
      "conditionalQuestions": []
    }
  ]
  */
};
