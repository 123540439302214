// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { TreatmentPhase } from '../../../lib/state';
import { AssessmentTemplate } from '../../../lib/services/models';

export const baselineDef = {
  name: 'baseline',
  label: 'Baseline',
  order: 1,
  assessments: [
    AssessmentTemplate.morningLog.name,
    AssessmentTemplate.eveningLog.name,
    AssessmentTemplate.sideEffects.name,
    AssessmentTemplate.medicationUse.name,
    AssessmentTemplate.pgi.name,
    AssessmentTemplate.nps.name
  ],
  baselineAssessments: [
    AssessmentTemplate.morningLog.name,
    AssessmentTemplate.eveningLog.name,
    AssessmentTemplate.demographics.name, // VA specific
    AssessmentTemplate.niqM.name,
    AssessmentTemplate.isi.name,
    AssessmentTemplate.osa.name,
    AssessmentTemplate.ess.name,
    AssessmentTemplate.swps.name, //1.5 will no longer use swps
    AssessmentTemplate.shiftWork.name,
    AssessmentTemplate.trauma.name,
    AssessmentTemplate.phq8.name,//1.5 will use phq2 version
    AssessmentTemplate.phq9.name, // Intermountain
    AssessmentTemplate.gad7.name,//1.5 will use gad2 version
    AssessmentTemplate.promis.name,
    AssessmentTemplate.peg.name,
    AssessmentTemplate.sleepGoalsPre.name,
    AssessmentTemplate.nsi.name,
    AssessmentTemplate.propr.name, //VA specific
    AssessmentTemplate.sleepHealthTools.name, //CICADA specific
    AssessmentTemplate.TBIPostConcussion.name, // TBI Study
    AssessmentTemplate.TBIPhq8.name, // TBI Study
    AssessmentTemplate.TBIGad7.name, // TBI Study
    AssessmentTemplate.TBIBriefFatigueInventory.name, // TBI Study
    AssessmentTemplate.TBIWHODAS2.name, // TBI Study
    AssessmentTemplate.TBIInsomniaTreatmentAcceptability.name, // TBI Study
    AssessmentTemplate.TBISideEffects.name, // TBI Study
    AssessmentTemplate.TBIPcl5.name, // TBI Study
  ]
} as TreatmentPhase;
