export const TBIGad7Def = {
  name: 'tbi-gad7',
  description: 'Generalized Anxiety Disorder',
  instructions: '',
  /*
  Note: the questions property must be empty here (but available in the database) or this assessment will be made available for all patients

  questions: [
    {
      "id": 0,
      "questionText": "Over the last 2 weeks, how often have you been bothered by:",
      "questionEmphasis": "Feeling nervous, anxious, or on edge",
      "questionType": "radio",
      "uniqueAnswerId": "GAD7_NERVOUS",
      "autofill": false,
      "answerOptions": [
        {
          "display": "Not at all",
          "value": 0
        },
        {
          "display": "Several Days",
          "value": 1
        },
        {
          "display": "More than half the days",
          "value": 2
        },
        {
          "display": "Nearly every day",
          "value": 3
        }
      ],
      "conditionalQuestions": []
    },
    {
      "id": 1,
      "questionText": "Over the last 2 weeks, how often have you been bothered by:",
      "questionEmphasis": "Not being able to stop or control worrying",
      "questionType": "radio",
      "uniqueAnswerId": "GAD7_CONTROL",
      "autofill": false,
      "answerOptions": [
        {
          "display": "Not at all",
          "value": 0
        },
        {
          "display": "Several Days",
          "value": 1
        },
        {
          "display": "More than half the days",
          "value": 2
        },
        {
          "display": "Nearly every day",
          "value": 3
        }
      ],
      "conditionalQuestions": []
    },
    {
      "id": 2,
      "questionText": "Over the last 2 weeks, how often have you been bothered by:",
      "questionEmphasis": "Worrying too much about different things",
      "questionType": "radio",
      "uniqueAnswerId": "GAD7_WORRYING",
      "autofill": false,
      "answerOptions": [
        {
          "display": "Not at all",
          "value": 0
        },
        {
          "display": "Several Days",
          "value": 1
        },
        {
          "display": "More than half the days",
          "value": 2
        },
        {
          "display": "Nearly every day",
          "value": 3
        }
      ],
      "conditionalQuestions": []
    },
    {
      "id": 3,
      "questionText": "Over the last 2 weeks, how often have you been bothered by:",
      "questionEmphasis": "Trouble relaxing",
      "questionType": "radio",
      "uniqueAnswerId": "GAD7_RELAXING",
      "autofill": false,
      "answerOptions": [
        {
          "display": "Not at all",
          "value": 0
        },
        {
          "display": "Several Days",
          "value": 1
        },
        {
          "display": "More than half the days",
          "value": 2
        },
        {
          "display": "Nearly every day",
          "value": 3
        }
      ],
      "conditionalQuestions": []
    },
    {
      "id": 4,
      "questionText": "Over the last 2 weeks, how often have you been bothered by:",
      "questionEmphasis": "Being so restless that it is hard to sit still",
      "questionType": "radio",
      "uniqueAnswerId": "GAD7_RESTLESS",
      "autofill": false,
      "answerOptions": [
        {
          "display": "Not at all",
          "value": 0
        },
        {
          "display": "Several Days",
          "value": 1
        },
        {
          "display": "More than half the days",
          "value": 2
        },
        {
          "display": "Nearly every day",
          "value": 3
        }
      ],
      "conditionalQuestions": []
    },
    {
      "id": 5,
      "questionText": "Over the last 2 weeks, how often have you been bothered by:",
      "questionEmphasis": "Becoming easily annoyed or irritable",
      "questionType": "radio",
      "uniqueAnswerId": "GAD7_IRRITABLE",
      "autofill": false,
      "answerOptions": [
        {
          "display": "Not at all",
          "value": 0
        },
        {
          "display": "Several Days",
          "value": 1
        },
        {
          "display": "More than half the days",
          "value": 2
        },
        {
          "display": "Nearly every day",
          "value": 3
        }
      ],
      "conditionalQuestions": []
    },
    {
      "id": 6,
      "questionText": "Over the last 2 weeks, how often have you been bothered by:",
      "questionEmphasis": "Feeling afraid as if something awful might happen",
      "questionType": "radio",
      "uniqueAnswerId": "GAD7_FEAR",
      "autofill": false,
      "answerOptions": [
        {
          "display": "Not at all",
          "value": 0
        },
        {
          "display": "Several Days",
          "value": 1
        },
        {
          "display": "More than half the days",
          "value": 2
        },
        {
          "display": "Nearly every day",
          "value": 3
        }
      ],
      "conditionalQuestions": []
    }
  ]
  */
};
