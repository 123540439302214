import { ReportDefinition } from "@noctem/web"

export const tacticText = `
  <div class='form-group row'>
    <label class='col-sm-12'>After reviewing the COAST algorithm-driven recommendations, sleep diary data, and self-report measure scores, this provider sent the following recommendations to the patient on {0}:</label>
    <label class='col-sm-8'>Bed Time No Earlier Than Sent:</label><label class='col-sm-4'>{1}</label>
    <label class='col-sm-8'>Wake Time No Later Than Sent:</label><label class='col-sm-4'>{2}</label>
    <label class='col-sm-8'>With the following tactics:</label><label class='col-sm-4'>{3}</label>
    <label class='col-sm-8'>Phone call w/ patient:</label><label class='col-sm-4'>{4}</label>
  </div>
`;

export const sleepGoalsText = `
    <label class='col-sm-12'><h4>Patient endorsed following sleep goals:</h4></label>
    <label class='col-sm-12'>{0}</label>
`;

export const patientChallengesText = `
    <label class='col-sm-12'><h4>Patient endorses the following expected challenges for implementing behavioral changes:</h4></label>
    <label class='col-sm-12'>{0}</label>
`;

export const medicationUseText = `
    <label class='col-sm-8'>Self-reported new or changes in medications this week:</label><label class='col-sm-4'>{0}</label>
`;

export const defaultFieldText = `
    <label class='col-sm-8'>{0} </label><label class='col-sm-4'>{1}</label>
`;

export const medicationsDetailsText = `
    <label class='col-sm-8'>Medications Added: </label><label class='col-sm-4'>{0}</label>
    <label class='col-sm-8'>Medications Changed: </label><label class='col-sm-4'>{1}</label>
`;

export const clinicalSummaryText = `
  <label class='col-sm-12 mt-2'><h4>Clinical Summary and Interpretation</h4></label>
  <label class='col-sm-4'>DATE: </label><label class='col-sm-8'>{0}</label>

  <!-- Removing form components
  <label class='col-sm-12'>SUMMARY: </label>
  <div class='col-sm-12'><textarea class='form-input col-sm-12'></textarea></div>
  -->
`;

export const pgiText = `
<div class='form-group row'>
<label class='col-sm-8'>Patient Rated Global Improvement after baseline monitoring week: </label><label class='col-sm-4'>{0}</label>
</div>`;


export const notesText = `
<label class='col-sm-12 mt-2'><h4>Provider Notes:</h4></label>
<div class='col-sm-12'>
  {0}
</div>
`;

export const clinicalInterpretation = `
  <!-- Removing form components
  <label class='col-sm-12 mt-2'><h4>Clinical Interpretation / Disposition:</h4></label>
  <textarea class='col-sm-12'></textarea>
  -->
`;

export const additionalInfo = `
  <!-- Removing form components
  <div class='form-group row'>
  <label class='col-sm-8'>Additional Information:</label>
  <div class='col-sm-8'><textarea></textarea></div>
  </div>
  -->
`;

export const interventionReport:ReportDefinition = {
  __i: null,
  id:0,
  name: 'intervention-report',
  title: 'Intervention Report',
  dataMapping: [
    {name: 'sleep-latency', calcType: 'average', includeStandardDeviation:true},
    {name: 'time-in-bed', calcType:'average', includeStandardDeviation:true},
    {name: 'total-sleep-time', calcType:'average', includeStandardDeviation:true},
    {name: 'had-caffeinated-drinks', calcType:'indicated', includeStandardDeviation:true},
    {name: 'total-caffeine-intake', calcType:'average', includeStandardDeviation:true},
    {name: 'had-alcoholic-drinks', calcType:'indicated', includeStandardDeviation:true},
    {name: 'total-alcohol-intake', calcType:'average', includeStandardDeviation:true},
    {name: 'took-naps', calcType:'indicated', includeStandardDeviation:true},
    {name: 'number-of-naps', calcType:'average', includeStandardDeviation:true},
    {name: 'sleep-efficiency', calcType:'average', includeStandardDeviation:true},
    {name: 'waso', calcType:'average', includeStandardDeviation:true},
    {name: 'rise-time', calcType:'average', includeStandardDeviation:true},
    {name: 'attempt-to-sleep', calcType:'average', includeStandardDeviation:true},
    {name: 'ess-weekly-overall', calcType: 'average',label: 'Epworth Sleepiness Scale:', includeInWeeks: ['week\\d+$', 'post']},
    {name: 'ess-overall', calcType: 'average', label: 'Epworth Sleepiness Scale:', includeInWeeks: ['baseline', 'followup\\d+$']},

    // TODO: although administered in added weeks, the ISI does not show for any except for weeks 2 & 4. As more people use the add weeks function, modify to sow also for each additional week added
    {name: 'isi-overall', calcType: 'average', label: 'Insomnia Severity Index:', includeInWeeks: ['baseline', 'week2', 'week4', 'followup\\d+$', 'post']},
    {name: 'phq-overall', calcType: 'average', label: 'Patient Health Questionnaire – 8 items:', includeInWeeks: ['baseline', 'post', 'followup\\d+$']},
    {name: 'phq2-overall', calcType: 'average', label: 'Patient Health Questionnaire – 2 items:', includeInWeeks: ['week\\d+$', 'followup1','baseline', 'post', 'followup\\d+$']},
    {name: 'gad7-overall', calcType: 'average', label: 'Generalized Anxiety Disorder - 7 Items:', includeInWeeks:['baseline', 'post', 'followup\\d+$']},
    {name: 'gad2-overall', calcType: 'average', label: 'Generalized Anxiety Disorder - 2 Items:', includeInWeeks:['week\\d+$', 'baseline', 'post', 'followup\\d+$']},
    {name: 'trauma', calcType: 'indicated', label: 'Endorsed trauma exposure', includeInWeeks:['baseline', 'post', 'followup\\d+$']},
    {name: 'peg-scale', calcType: 'average'},

    // Intermountain
    {name: 'phq9-overall', calcType: 'average', label: 'Patient Health Questionnaire – 9 items:', includeInWeeks: ['followup1','baseline', 'post', 'followup\\d+$']},
    {name: 'phq3-overall', calcType: 'average', label: 'Patient Health Questionnaire – 3 items:', includeInWeeks: ['week\\d+$']},

    {name: 'osa-diagnosed', calcType:'indicated', includeInWeeks:['baseline', 'post']},
    {name: 'osa-gasping'},
    {name: 'osa-stop-breathing'},
    {name: 'osa-snore-loudly'},
    {name: 'osa-dry-mouth'},
    {name: 'osa-congestion-headaches'},
    {name: 'osa-blood-pressure'},
    {name: 'osa-sleepy'},
    {name: 'osa-neck-size'},
    {name: 'pcl5-overall', calcType:'average'},
    {name: 'pcPtsd-overall', calcType:'average'},

    {name: 'side-effects-fatigue', calcType: 'concat', groupName:'side-effects'},
    {name: 'side-effects-headaches', calcType: 'concat', groupName:'side-effects'},
    {name: 'side-effects-dizziness', calcType: 'concat', groupName:'side-effects'},
    {name: 'side-effects-sexual-interest', calcType: 'concat', groupName:'side-effects'},
    {name: 'side-effects-drowsiness', calcType: 'concat', groupName:'side-effects'},
    {name: 'side-effects-muscle-tightness', calcType: 'concat', groupName:'side-effects'},
    {name: 'side-effects-sensitivity', calcType: 'concat', groupName:'side-effects'},

    // sleep goals
    {name: 'sleep-goals-pre-quick', calcType: 'concat', groupName:'sleep-goals'},
    {name: 'sleep-goals-pre-stay',  calcType: 'concat', groupName:'sleep-goals'},
    {name: 'sleep-goals-pre-three', calcType: 'concat', groupName:'sleep-goals'},
    {name: 'sleep-goals-pre-refreshed', calcType: 'concat', groupName:'sleep-goals'},
    {name: 'sleep-goals-pre-nightmares', calcType: 'concat', groupName:'sleep-goals'},
    {name: 'sleep-goals-pre-racing', calcType: 'concat', groupName:'sleep-goals'},
    {name: 'sleep-goals-pre-share-bed', calcType: 'concat', groupName:'sleep-goals'},
    {name: 'sleep-goals-pre-get-along', calcType: 'concat', groupName:'sleep-goals'},
    {name: 'sleep-goals-pre-yell', calcType: 'concat', groupName:'sleep-goals'},
    {name: 'sleep-goals-pre-tossing', calcType: 'concat', groupName:'sleep-goals'},
    {name: 'sleep-goals-pre-alcohol', calcType: 'concat', groupName:'sleep-goals'},
    {name: 'sleep-goals-pre-sleep-aids', calcType: 'concat', groupName:'sleep-goals'},
    {name: 'sleep-goals-pre-health', calcType: 'concat', groupName:'sleep-goals'},
    {name: 'sleep-goals-pre-work', calcType: 'concat', groupName:'sleep-goals'},
    {name: 'sleep-goals-pre-other', calcType: 'concat', groupName:'sleep-goals'},

    {name: 'sleep-goals-pre-tv', calcType: 'concat', groupName:'sleep-challenges'},
    {name: 'sleep-goals-pre-phone', calcType: 'concat', groupName:'sleep-challenges'},
    {name: 'sleep-goals-pre-logs', calcType: 'concat', groupName:'sleep-challenges'},
    {name: 'sleep-goals-pre-caffeine', calcType: 'concat', groupName:'sleep-challenges'},
    {name: 'sleep-goals-pre-schedule', calcType: 'concat', groupName:'sleep-challenges'},
    {name: 'sleep-goals-pre-tech', calcType: 'concat', groupName:'sleep-challenges'},
    {name: 'sleep-goals-pre-habits', calcType: 'concat', groupName:'sleep-challenges'},
    {name: 'sleep-goals-pre-me', calcType: 'concat', groupName:'sleep-challenges'},
    {name: 'sleep-goals-pre-well', calcType: 'concat', groupName:'sleep-challenges'},
    {name: 'sleep-challenges-pre-other', calcType: 'concat', groupName:'sleep-challenges'},

    {name: 'medication-use-change', calcType: 'indicated'},
    {name: 'medication-use-add', calcType: 'indicated'},
    {name: 'medication-use-what', calcType: 'text'},
    {name: 'medication-use-change-what', calcType: 'text'},

    {name: 'pgi-rating', calcType: 'lookup',label: 'Patient Rated Global Improvement after baseline monitoring week:'},

  ],
  pageTemplates: [
    {
      name:'summary',
      label: 'Summary',
      required: true,
      content: `<section>
      <div class="form-group row align-items-start justify-content-between">
        <div class="d-flex col-sm-7 flex-column justify-content-between">
          <div class="d-flex">
            <label class="font-weight-bold" style="width:100px; min-width:100px;">Patient&nbsp;ID:&nbsp;&nbsp;</label>
            <label>**patientId**</label>
          </div>
          <div class="d-flex">
            <label class="font-weight-bold" style="width:100px; min-width:100px;">Alt.&nbsp;ID:&nbsp;&nbsp;</label>
            <label>**externalId**</label>
          </div>
          <div class="d-flex">
            <label class="font-weight-bold" style="width:100px; min-width:100px;">Diagnostic Code(s):&nbsp;&nbsp;</label>
            <label>**diagnosticCodes**</label>
          </div>
        </div>
        <div class="d-flex col-sm-1"></div>
        <div class="d-flex col-sm-4 justify-content-end align-items-center">
          <label class="font-weight-bold text-right">Therapy:&nbsp;&nbsp;</label>
          <select class="therapy-type form-control w-60">
            <option value="0" selected>Individual</option>
            <option value="1">Group</option>
          </select>
        </div>
      </div>
    </section>
    
    <section>
      <div class="form-group row">
        <div class="d-flex col-sm-7">
        <div class="d-flex">
        <label class="font-weight-bold" style="width:100px; min-width:100px;">Treatment Dates:</label>
        <label>**treatmentStartDate** - **currentDate**</label>
        </div>
        </div>
      </div>
    </section>

    <!-- Removing form components
    <section>
      <div class="form-group row">
        <label class="col-sm-6 font-weight-bold">Patient primary presenting sleep complaints:</label>
        <div class="col-sm-6">
          <input
            type="checkbox"
            class="form-check-input"
            id="complaint1"
            name="complaint1"
            value="0"
          /><label for="complaint1">Insomnia symptoms</label><br />
          <input
            type="checkbox"
            class="form-check-input"
            id="complaint2"
            name="complaint2"
            value="1"
          /><label for="complaint2">Disturbing dreams</label><br />
          <input
            type="checkbox"
            class="form-check-input"
            id="complaint3"
            name="complaint3"
            value="2"
          /><label for="complaint3">Racing thoughts/ruminations at bedtime or at night</label><br />
          <input
            type="checkbox"
            class="form-check-input"
            id="complaint4"
            name="complaint4"
            value="3"
          /><label for="complaint4">Physical tension</label><br />
          <input
            type="checkbox"
            class="form-check-input"
            id="complaint5"
            name="complaint5"
            value="4"
          /><label for="complaint5">Shift work</label><br />
          <input
            type="checkbox"
            class="form-check-input"
            id="complaint6"
            name="complaint6"
            value="5"
          /><label for="complaint6">Fatigue</label><br />
          <input
            type="checkbox"
            class="form-check-input"
            id="complaint7"
            name="complaint7"
            value="6"
          /><label for="complaint7">Daytime sleepiness</label><br />
          <div class="row p-0 m-0">
            <div class="col-sm-*">
              <input
                type="checkbox"
                class="form-check-input"
                id="complaint8"
                name="complaint8"
                value="7"
              /><label for="complaint8">Other:</label>
            </div>
            <div class="col-sm-* pl-1">
              <input class="other-text" type="text" />
            </div>
          </div>
        </div>
      </div>
    </section>
    -->
    
    <!-- Removing form components
    <section>
      <div class="form-group row">
        <label class="col-sm-2 font-weight-bold">Diagnosis:</label>
        <textarea class="form-control col-sm-10"></textarea>
      </div>
    </section>
    -->
    
    <section>
      <h4>
        Clinician Operated Assistive Sleep Technology (COAST™) for CBT for Insomnia or other
        behavioral sleep-related disturbances
      </h4>
      <p>
        This evidenced-based intervention assistive technology incorporates the primary principles of
        effective cognitive behavioral treatment for Insomnia including education, stimulus control,
        sleep restriction, cognitive reappraisal, and relaxation training. Educational and
        interventional modules for imagery rehearsal therapy for nightmares, fatigue mitigation
        strategies, tactical napping, and managing circadian challenges due to shift work or jet lag
        are also included. Providers determine whether algorithm-driven recommendations to improve
        sleep based on the patient’s report are appropriate before modules are forwarded to the COAST™
        patient app. Providers can modify algorithm-driven suggestions and make their own selection of
        interventional modules to be sent to a patient.
      </p>
      <p>
      While using the COAST platform with their providers, patients /users are asked to maintain an
      electronic sleep log daily throughout the intervention. They are also instructed to implement
      the behavioral changes recommended by their providers and that are detailed in the modules
      they receive on their app. Self-report of side effects, progress, and symptoms are monitored
      weekly. Data entries are available for review on the COAST portal and are summarized in this
      report.
    </p>
    </section>
  
    <section>
     
      <p>
        <span class="font-weight-bold">Informed Consent:</span> The patient was provided with a
        detailed explanation of how the COAST app will be utilized and monitored by their provider to
        manage sleep complaints over the next few weeks. S/he/they was also provided with information
        regarding the use of data, security measures in place to protect information they share via
        the app. The patient provided consent to proceed by signing the End-User License Agreement
        and/or consent form. The patient has agreed to participate in this treatment voluntarily.
      </p>

      <!-- Removing form components
      <div class="form-group row">
        <label class="col-sm-3 font-weight-bold">Additional Information:</label>
        <textarea class="form-input col-sm-8"></textarea>
      </div>
      -->

      <div class="spacer">&nbsp;</div>
    </section>
    `
    },
    {
      name:'treatmentWeeks',
      label: '',
      required: false,
      content:`
       
      <section>
      <h5 class="timepoint-section-title">**weekLabel**; Sleep and Symptoms Assessment [**startDate** - **endDate**]:</h5>

      **prescription-info**
  
      <div class="form-group row">
        <label class="col-sm-12"><h4>Self-Report Questionnaires:</h4></label>
        **osa** **isi-overall** **ess-weekly-overall** **ess-overall** **phq-overall** **phq2-overall** **phq9-overall** **phq3-overall**
        **gad7-overall** **gad2-overall** **trauma** **trauma-details**
        <label class="col-sm-8">Patient rated pain level: </label
        ><label class="col-sm-4">**peg-scale**</label>
        <label class="col-sm-8 mb-1">Symptoms endorsed: </label
        ><label class="col-sm-4">**side-effects**</label>
      </div>
  
      </section> 

      <section>
        <div class='form-group row'>
          <label class='col-sm-12'><h4>Data from **number-of-entries** entries, the sleep diary revealed the following:</h4></label>
          <label class='col-sm-8'>Average SL (Sleep onset latency): M + SD: </label><label class='col-sm-4'>**sleep-latency** </label>
          <label class='col-sm-8'>Average TST (Total sleep time):  M + SD: </label><label class='col-sm-4'>**total-sleep-time**</label>
          <label class='col-sm-8'>Average TIB (Time in bed): M + SD: </label><label class='col-sm-4'>**time-in-bed**</label>
          <label class='col-sm-8'>Average WASO (Wakefulness after sleep onset): M + SD: </label><label class='col-sm-4'>**waso**</label>
          <label class='col-sm-8'>Average SE% (Sleep efficiency): M + SD: </label><label class='col-sm-4'>**sleep-efficiency**</label>
          <label class='col-sm-8'>Average bedtime: M + SD: </label><label class='col-sm-4'>**attempt-to-sleep**</label>
          <label class='col-sm-8'>Average rise time: M + SD: </label><label class='col-sm-4'>**rise-time**</label>
        </div>
        
      </section>

        <section>
          <div class='form-group row'>
            <label class='col-sm-12'><h4>On the daily logs, the patient reported:</h4></label>
            <label class='col-sm-8'>Average caffeine intake per day: </label><label class='col-sm-4'>**total-caffeine-intake**</label>
            <label class='col-sm-8'>Average naps per day:</label><label class='col-sm-4'>**number-of-naps**</label>
            <label class='col-sm-8'>Average alcoholic drinks per day:</label><label class='col-sm-4'>**total-alcohol-intake**</label>
          </div>
          <div class='form-group row'>
            **medications-changed**
            **medications-details**
            **sleep-goals**
            **sleep-challenges**
            **pgi-rating**
            **provider-notes**
            **clinical-summary**
            **clinical-interpretation**
          </div>

          <div class="spacer">&nbsp;</div>
        </section>`
    },
    {
      name:'ctt',
      label: 'Remote Therapeutic Monitoring (RTM)',
      required: false,
      content: `
        <section>
          <div class='form-group row'>
            <label class='col-sm-12'><h5 class='timepoint-section-title'>Remote Therapeutic Monitoring (RTM)</h5></label>
            <p class='col-sm-12'>
              The summary below provides basic information in support of remote therapeutic monitoring (RTM) services delivered by a qualified healthcare provider to a patient via the COAST platform. 
              "Entries" refer to the number of days of data collection ("entries") completed by a patient. Multiple entries in a single day are computed as a single day of data collection. 
              Total time reflects spent monitoring and/or reviewing the patient's symptoms, adherence, and progress; sending recommendations to the patients, documentation, text messages and other activities completed in a patient's chart. 
              Total time may reflect the total cumulative spent on these activities by multiple providers when a clinic uses a team management approach to patient care.
            </p>
            <p class='col-sm-12'>
              DISCLAIMER: NOCTEM does NOT provide insurance billing services or guarantees.
              While we provide a convenient platform for you to track information that may allow you to provide certain healthcare services
              that may or may not be reimbursable by federal or state health insurance payers, we are not billing experts,
              we do not provide billing services, and any information provided to you with respect to billing and reimbursement,
              such as billing reports, is for informational purposes only and should not be relied upon or constructed to guarantee
              reimbursement of any services.
            </p>
            
            **timeSessionResults**
            
          </div>

          <div class="spacer">&nbsp;</div>
        </section>`
    },
    {
      name:'messaging',
      label: 'Messaging History',
      required: false,
      content: `
        <section>
          <div class='form-group row'>
            <label class='col-sm-12'><h5 class='timepoint-section-title'>Messaging History</h5></label>
            <p class='col-sm-12'>Messaging history between all providers and the patient within the COAST platform. Note that provider messages in the transcript are emphasized in <strong>bold</strong>.</p>
          </div>
            
          **messagingResults**

          <!-- <div class="spacer">&nbsp;</div> -->
        </section>`
    },
  ]
}
