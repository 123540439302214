export const TBIClientSatisfationDef = {
  name: 'tbi-client-satisfaction',
  description: 'Client Satisfaction Questionnaire',
  instructions: 'Please help us improve our treatment program by answering some questions about the services you have received.',
  /*
  Note: the questions property must be empty here (but available in the database) or this assessment will be made available for all patients

  questions: [
    {
      "id": 0,
      "questionText": "How would you rate the quality of service you have received?",
      "questionEmphasis": "",
      "questionType": "radio",
      "uniqueAnswerId": "CLIENT-SATISFACTION_QUALITY",
      "autofill": false,
      "answerOptions": [
        {
          "display": "Excellent",
          "value": 4
        },
        {
          "display": "Good",
          "value": 3
        },
        {
          "display": "Fair",
          "value": 2
        },
        {
          "display": "Poor",
          "value": 1
        }
      ],
      "conditionalQuestions": []
    },
    {
      "id": 1,
      "questionText": "Did you get the kind of service you wanted?",
      "questionEmphasis": "",
      "questionType": "radio",
      "uniqueAnswerId": "CLIENT-SATISFACTION_KIND",
      "autofill": false,
      "answerOptions": [
        {
          "display": "No, definitely not",
          "value": 1
        },
        {
          "display": "No, not really",
          "value": 2
        },
        {
          "display": "Yes, generally",
          "value": 3
        },
        {
          "display": "Yes, definitely",
          "value": 4
        }
      ],
      "conditionalQuestions": []
    },
    {
      "id": 2,
      "questionText": "To what extent has our program met your needs?",
      "questionEmphasis": "",
      "questionType": "radio",
      "uniqueAnswerId": "CLIENT-SATISFACTION_NEEDS_MET",
      "autofill": false,
      "answerOptions": [
        {
          "display": "Almost all of my needs have been met",
          "value": 4
        },
        {
          "display": "Most of my needs have been met",
          "value": 3
        },
        {
          "display": "Only a few of my needs have been met",
          "value": 2
        },
        {
          "display": "None of my needs have been met",
          "value": 1
        }
      ],
      "conditionalQuestions": []
    },
    {
      "id": 3,
      "questionText": "If a friend were in need of similar help, would you recommend our program to her or him?",
      "questionEmphasis": "",
      "questionType": "radio",
      "uniqueAnswerId": "CLIENT-SATISFACTION_RECOMMEND",
      "autofill": false,
      "answerOptions": [
        {
          "display": "No, definitely not",
          "value": 1
        },
        {
          "display": "No, I dont't think so",
          "value": 2
        },
        {
          "display": "Yes, I think so",
          "value": 3
        },
        {
          "display": "Yes, definitely",
          "value": 4
        }
      ],
      "conditionalQuestions": []
    },
    {
      "id": 4,
      "questionText": "How satisfied are you with the amount of help you have received?",
      "questionEmphasis": "",
      "questionType": "radio",
      "uniqueAnswerId": "CLIENT-SATISFACTION_SATISFIED",
      "autofill": false,
      "answerOptions": [
        {
          "display": "Quite dissatisfied",
          "value": 1
        },
        {
          "display": "Indifferent or mildly dissatisfied",
          "value": 2
        },
        {
          "display": "Mostly satisfied",
          "value": 3
        },
        {
          "display": "Very satisfied",
          "value": 4
        }
      ],
      "conditionalQuestions": []
    },
    {
      "id": 5,
      "questionText": "Have the services you received helped you to deal more effectively with your problems?",
      "questionEmphasis": "",
      "questionType": "radio",
      "uniqueAnswerId": "CLIENT-SATISFACTION_HELPED_PROBLEMS",
      "autofill": false,
      "answerOptions": [
        {
          "display": "Yes, they helped a great deal",
          "value": 4
        },
        {
          "display": "Yes, they helped somewhat",
          "value": 3
        },
        {
          "display": "No, they really didn't help",
          "value": 2
        },
        {
          "display": "No, they seemed to make things worse",
          "value": 1
        }
      ],
      "conditionalQuestions": []
    },
    {
      "id": 6,
      "questionText": "In an overall, general sense, how satisfied are you with the service you have received?",
      "questionEmphasis": "",
      "questionType": "radio",
      "uniqueAnswerId": "CLIENT-SATISFACTION_OVERALL",
      "autofill": false,
      "answerOptions": [
        {
          "display": "Very satisfied",
          "value": 4
        },
        {
          "display": "Mostly satisfied",
          "value": 3
        },
        {
          "display": "Indifferent or mildly dissatisfied",
          "value": 2
        },
        {
          "display": "Quite dissatisfied",
          "value": 1
        }
      ],
      "conditionalQuestions": []
    },
    {
      "id": 7,
      "questionText": "If you were to seek help again, would you come back to our program?",
      "questionEmphasis": "",
      "questionType": "radio",
      "uniqueAnswerId": "CLIENT-SATISFACTION_AGAIN",
      "autofill": false,
      "answerOptions": [
        {
          "display": "No, definitely not",
          "value": 1
        },
        {
          "display": "No, I dont't think so",
          "value": 2
        },
        {
          "display": "Yes, I think so",
          "value": 3
        },
        {
          "display": "Yes, definitely",
          "value": 4
        }
      ],
      "conditionalQuestions": []
    }
  ]
  */
};
