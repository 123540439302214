export const TBIPcl5Def = {
  name: 'tbi-pcl5',
  description: 'Post-traumatic Stress Disorder Checklist',
  instructions:
    'The following is a list of problems that people sometimes have in response to a very stressful experience. Please read each problem carefully and then mark the response that indicates how much you have been bothered by that problem in the past month.',    
  /*
  Note: the questions property must be empty here (but available in the database) or this assessment will be made available for all patients

  questions: [
    {
      "id": 1,
      "questionText": "The following is a list of problems that people sometimes have in response to a very stressful experience. In the past month, how much were you bothered by:",
      "questionEmphasis": "Repeated, disturbing, and unwanted memories of the stressful experience?",
      "questionType": "radio",
      "uniqueAnswerId": "PCL5_UNWANTED_MEMORIES",
      "autofill": false,
      "answerOptions": [
        {
          "display": "Not at all",
          "value": 0
        },
        {
          "display": "A little bit",
          "value": 1
        },
        {
          "display": "Moderately",
          "value": 2
        },
        {
          "display": "Quite a bit",
          "value": 3
        },
        {
          "display": "Extremely",
          "value": 4
        }
      ],
      "conditionalQuestions": []
    },
    {
      "id": 2,
      "questionText": "In the past month, how much were you bothered by:",
      "questionEmphasis": "Repeated, disturbing dreams of the stressful experience?",
      "questionType": "radio",
      "uniqueAnswerId": "PCL5_DREAMS",
      "autofill": false,
      "answerOptions": [
        {
          "display": "Not at all",
          "value": 0
        },
        {
          "display": "A little bit",
          "value": 1
        },
        {
          "display": "Moderately",
          "value": 2
        },
        {
          "display": "Quite a bit",
          "value": 3
        },
        {
          "display": "Extremely",
          "value": 4
        }
      ],
      "conditionalQuestions": []
    },
    {
      "id": 3,
      "questionText": "In the past month, how much were you bothered by:",
      "questionEmphasis": "Suddenly feeling or acting as if the stressful experience were actually happening again (as if you were actually back there reliving it)?",
      "questionType": "radio",
      "uniqueAnswerId": "PCL5_RECUR",
      "autofill": false,
      "answerOptions": [
        {
          "display": "Not at all",
          "value": 0
        },
        {
          "display": "A little bit",
          "value": 1
        },
        {
          "display": "Moderately",
          "value": 2
        },
        {
          "display": "Quite a bit",
          "value": 3
        },
        {
          "display": "Extremely",
          "value": 4
        }
      ],
      "conditionalQuestions": []
    },
    {
      "id": 4,
      "questionText": "In the past month, how much were you bothered by:",
      "questionEmphasis": "Feeling very upset when something reminded you of the stressful experience?",
      "questionType": "radio",
      "uniqueAnswerId": "PCL5_REMINDER",
      "autofill": false,
      "answerOptions": [
        {
          "display": "Not at all",
          "value": 0
        },
        {
          "display": "A little bit",
          "value": 1
        },
        {
          "display": "Moderately",
          "value": 2
        },
        {
          "display": "Quite a bit",
          "value": 3
        },
        {
          "display": "Extremely",
          "value": 4
        }
      ],
      "conditionalQuestions": []
    },
    {
      "id": 5,
      "questionText": "In the past month, how much were you bothered by:",
      "questionEmphasis": "Having strong physical reactions when something reminded you of the stressful experience (for example, heart pounding, trouble breathing, sweating)?",
      "questionType": "radio",
      "uniqueAnswerId": "PCL5_PHYSICAL",
      "autofill": false,
      "answerOptions": [
        {
          "display": "Not at all",
          "value": 0
        },
        {
          "display": "A little bit",
          "value": 1
        },
        {
          "display": "Moderately",
          "value": 2
        },
        {
          "display": "Quite a bit",
          "value": 3
        },
        {
          "display": "Extremely",
          "value": 4
        }
      ],
      "conditionalQuestions": []
    },
    {
      "id": 6,
      "questionText": "In the past month, how much were you bothered by:",
      "questionEmphasis": "Avoiding memories, thoughts, or feelings related to the stressful experience?",
      "questionType": "radio",
      "uniqueAnswerId": "PCL5_AVOIDING_THOUGHTS",
      "autofill": false,
      "answerOptions": [
        {
          "display": "Not at all",
          "value": 0
        },
        {
          "display": "A little bit",
          "value": 1
        },
        {
          "display": "Moderately",
          "value": 2
        },
        {
          "display": "Quite a bit",
          "value": 3
        },
        {
          "display": "Extremely",
          "value": 4
        }
      ],
      "conditionalQuestions": []
    },
    {
      "id": 7,
      "questionText": "In the past month, how much were you bothered by:",
      "questionEmphasis": "Avoiding external reminders of the stressful experience (for example, people, places, conversations, activities, objects, or situations)?",
      "questionType": "radio",
      "uniqueAnswerId": "PCL5_AVOIDING_EXTERNAL",
      "autofill": false,
      "answerOptions": [
        {
          "display": "Not at all",
          "value": 0
        },
        {
          "display": "A little bit",
          "value": 1
        },
        {
          "display": "Moderately",
          "value": 2
        },
        {
          "display": "Quite a bit",
          "value": 3
        },
        {
          "display": "Extremely",
          "value": 4
        }
      ],
      "conditionalQuestions": []
    },
    {
      "id": 8,
      "questionText": "In the past month, how much were you bothered by:",
      "questionEmphasis": "Trouble remembering important parts of the stressful experience?",
      "questionType": "radio",
      "uniqueAnswerId": "PCL5_MEMORY",
      "autofill": false,
      "answerOptions": [
        {
          "display": "Not at all",
          "value": 0
        },
        {
          "display": "A little bit",
          "value": 1
        },
        {
          "display": "Moderately",
          "value": 2
        },
        {
          "display": "Quite a bit",
          "value": 3
        },
        {
          "display": "Extremely",
          "value": 4
        }
      ],
      "conditionalQuestions": []
    },
    {
      "id": 9,
      "questionText": "In the past month, how much were you bothered by:",
      "questionEmphasis": "Having strong negative beliefs about yourself, other people, or the world (for example, having thoughts such as: I am bad, there is something seriously wrong with me, no one can be trusted, the world is completely dangerous)?",
      "questionType": "radio",
      "uniqueAnswerId": "PCL5_SELF_IMAGE",
      "autofill": false,
      "answerOptions": [
        {
          "display": "Not at all",
          "value": 0
        },
        {
          "display": "A little bit",
          "value": 1
        },
        {
          "display": "Moderately",
          "value": 2
        },
        {
          "display": "Quite a bit",
          "value": 3
        },
        {
          "display": "Extremely",
          "value": 4
        }
      ],
      "conditionalQuestions": []
    },
    {
      "id": 10,
      "questionText": "In the past month, how much were you bothered by:",
      "questionEmphasis": "Blaming yourself or someone else for the stressful experience or what happened after it?",
      "questionType": "radio",
      "uniqueAnswerId": "PCL5_BLAME",
      "autofill": false,
      "answerOptions": [
        {
          "display": "Not at all",
          "value": 0
        },
        {
          "display": "A little bit",
          "value": 1
        },
        {
          "display": "Moderately",
          "value": 2
        },
        {
          "display": "Quite a bit",
          "value": 3
        },
        {
          "display": "Extremely",
          "value": 4
        }
      ],
      "conditionalQuestions": []
    },
    {
      "id": 11,
      "questionText": "In the past month, how much were you bothered by:",
      "questionEmphasis": "Having strong negative feelings such as fear, horror, anger, guilt, or shame?",
      "questionType": "radio",
      "uniqueAnswerId": "PCL5_FEELINGS",
      "autofill": false,
      "answerOptions": [
        {
          "display": "Not at all",
          "value": 0
        },
        {
          "display": "A little bit",
          "value": 1
        },
        {
          "display": "Moderately",
          "value": 2
        },
        {
          "display": "Quite a bit",
          "value": 3
        },
        {
          "display": "Extremely",
          "value": 4
        }
      ],
      "conditionalQuestions": []
    },
    {
      "id": 12,
      "questionText": "In the past month, how much were you bothered by:",
      "questionEmphasis": "Loss of interest in activities that you used to enjoy?",
      "questionType": "radio",
      "uniqueAnswerId": "PCL5_INTEREST",
      "autofill": false,
      "answerOptions": [
        {
          "display": "Not at all",
          "value": 0
        },
        {
          "display": "A little bit",
          "value": 1
        },
        {
          "display": "Moderately",
          "value": 2
        },
        {
          "display": "Quite a bit",
          "value": 3
        },
        {
          "display": "Extremely",
          "value": 4
        }
      ],
      "conditionalQuestions": []
    },
    {
      "id": 13,
      "questionText": "In the past month, how much were you bothered by:",
      "questionEmphasis": "Feeling distant or cut off from other people?",
      "questionType": "radio",
      "uniqueAnswerId": "PCL5_DISTANT",
      "autofill": false,
      "answerOptions": [
        {
          "display": "Not at all",
          "value": 0
        },
        {
          "display": "A little bit",
          "value": 1
        },
        {
          "display": "Moderately",
          "value": 2
        },
        {
          "display": "Quite a bit",
          "value": 3
        },
        {
          "display": "Extremely",
          "value": 4
        }
      ],
      "conditionalQuestions": []
    },
    {
      "id": 14,
      "questionText": "In the past month, how much were you bothered by:",
      "questionEmphasis": "Trouble experiencing positive feelings (for example, being unable to feel happiness or have loving feelings for people close to you)?",
      "questionType": "radio",
      "uniqueAnswerId": "PCL5_POSITIVE",
      "autofill": false,
      "answerOptions": [
        {
          "display": "Not at all",
          "value": 0
        },
        {
          "display": "A little bit",
          "value": 1
        },
        {
          "display": "Moderately",
          "value": 2
        },
        {
          "display": "Quite a bit",
          "value": 3
        },
        {
          "display": "Extremely",
          "value": 4
        }
      ],
      "conditionalQuestions": []
    },
    {
      "id": 15,
      "questionText": "In the past month, how much were you bothered by:",
      "questionEmphasis": "Irritable behavior, angry outbursts, or acting aggressively?",
      "questionType": "radio",
      "uniqueAnswerId": "PCL5_IRRITATION",
      "autofill": false,
      "answerOptions": [
        {
          "display": "Not at all",
          "value": 0
        },
        {
          "display": "A little bit",
          "value": 1
        },
        {
          "display": "Moderately",
          "value": 2
        },
        {
          "display": "Quite a bit",
          "value": 3
        },
        {
          "display": "Extremely",
          "value": 4
        }
      ],
      "conditionalQuestions": []
    },
    {
      "id": 16,
      "questionText": "In the past month, how much were you bothered by:",
      "questionEmphasis": "Taking too many risks or doing things that could cause you harm?",
      "questionType": "radio",
      "uniqueAnswerId": "PCL5_RISKS",
      "autofill": false,
      "answerOptions": [
        {
          "display": "Not at all",
          "value": 0
        },
        {
          "display": "A little bit",
          "value": 1
        },
        {
          "display": "Moderately",
          "value": 2
        },
        {
          "display": "Quite a bit",
          "value": 3
        },
        {
          "display": "Extremely",
          "value": 4
        }
      ],
      "conditionalQuestions": []
    },
    {
      "id": 17,
      "questionText": "In the past month, how much were you bothered by:",
      "questionEmphasis": "Being 'super-alert' or watchful or on guard?",
      "questionType": "radio",
      "uniqueAnswerId": "PCL5_ALERT",
      "autofill": false,
      "answerOptions": [
        {
          "display": "Not at all",
          "value": 0
        },
        {
          "display": "A little bit",
          "value": 1
        },
        {
          "display": "Moderately",
          "value": 2
        },
        {
          "display": "Quite a bit",
          "value": 3
        },
        {
          "display": "Extremely",
          "value": 4
        }
      ],
      "conditionalQuestions": []
    },
    {
      "id": 18,
      "questionText": "In the past month, how much were you bothered by:",
      "questionEmphasis": "Feeling jumpy or easily startled?",
      "questionType": "radio",
      "uniqueAnswerId": "PCL5_JUMPY",
      "autofill": false,
      "answerOptions": [
        {
          "display": "Not at all",
          "value": 0
        },
        {
          "display": "A little bit",
          "value": 1
        },
        {
          "display": "Moderately",
          "value": 2
        },
        {
          "display": "Quite a bit",
          "value": 3
        },
        {
          "display": "Extremely",
          "value": 4
        }
      ],
      "conditionalQuestions": []
    },
    {
      "id": 19,
      "questionText": "In the past month, how much were you bothered by:",
      "questionEmphasis": "Having difficulty concentrating?",
      "questionType": "radio",
      "uniqueAnswerId": "PCL5_CONCENTRATION",
      "autofill": false,
      "answerOptions": [
        {
          "display": "Not at all",
          "value": 0
        },
        {
          "display": "A little bit",
          "value": 1
        },
        {
          "display": "Moderately",
          "value": 2
        },
        {
          "display": "Quite a bit",
          "value": 3
        },
        {
          "display": "Extremely",
          "value": 4
        }
      ],
      "conditionalQuestions": []
    },
    {
      "id": 20,
      "questionText": "In the past month, how much were you bothered by:",
      "questionEmphasis": "Trouble falling or staying asleep?",
      "questionType": "radio",
      "uniqueAnswerId": "PCL5_SLEEP",
      "autofill": false,
      "answerOptions": [
        {
          "display": "Not at all",
          "value": 0
        },
        {
          "display": "A little bit",
          "value": 1
        },
        {
          "display": "Moderately",
          "value": 2
        },
        {
          "display": "Quite a bit",
          "value": 3
        },
        {
          "display": "Extremely",
          "value": 4
        }
      ],
      "conditionalQuestions": []
    }
  ]
  */
};
